interface ContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export default function Container({ className = "lg:px-20 lg:py-15 md:px-12 md:py:8 sm:px-8 sm:py-4 px-4 py-2", children }: ContainerProps) {
  return (
    <div
      className={`w-full mx-auto ${className}`}
    >
      {children}
    </div>
  );
}