import { useState } from "react";
import { useAppDispatch } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { submitEmailToRecoverPassword } from "../../slices/auth";
import { generateTranslatedText } from "../../utils/boilerplate";
import { LifebuoyIcon } from "@heroicons/react/24/solid";
import { validateEmail } from "../../utils/validate";
import Card from "../layout/Card";
import TextInput from "../forms/TextInput";
import Button from "../layout/Button";

export default function ForgotMyPasswordForm() {
  const dispatch = useAppDispatch();

  // App level state
  const language = "EN";

  // Component level state
  const [state, setState] = useState({ email: "" });

  // Functions
  async function submitEmailForPasswordRecovery(e: any) {
    e.preventDefault();

    const { email } = state;
    if (email === "") {
      dispatch(
        addAlert(generateTranslatedText("email_required", language), "danger")
      );
      return;
    }

    // Validate email
    const check = validateEmail(email);
    if (!check) {
      dispatch(
        addAlert(generateTranslatedText("invalid_email", language), "danger")
      );
      return;
    }

    // Submit email for recovery
    const res: any = await dispatch(submitEmailToRecoverPassword({ email }));
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("password_recovery_email_sent", language),
          "success"
        )
      );
    } else {
      dispatch(
        addAlert(
          generateTranslatedText("password_recovery_email_error", language),
          "danger"
        )
      );
    }
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      {/* Card */}
      <Card
        colour="black-light"
        paddingX={8}
        paddingY={8}
        borderColour="warning"
        className=" w-full max-w-md space-y-8"
      >
        {/* Header */}
        <div>
          <a href="/">
            <img
              className="mx-auto h-12 w-auto rounded-lg"
              src="/img/logo/logo_transparent.svg"
              alt="FilmWaffle"
            />
          </a>
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
            {generateTranslatedText("forgotten_password_title", language)}
          </h2>
          <p className="text-sm text-center mt-2 text-secondary italic">
            {generateTranslatedText("forgotton_password_subtext", language)}
          </p>
        </div>

        {/* Form */}
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => submitEmailForPasswordRecovery(e)}
        >
          <div className="-space-y-px rounded-md">
            <div>

              {/* Email */}
              <TextInput
                id="email"
                type="email"
                label={generateTranslatedText("email", "EN")}
                labelColour="text-white"
                showLabel={false}
                placeholder={generateTranslatedText("email_placeholder", "EN")}
                autoComplete="email"
                required={true}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                value={state.email}
              />


            </div>
          </div>

          <div>
            <Button
              bgColour="warning hover:bg-warning-dark"
              textColour="black"
              borderColour="black"
              className="text-black"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LifebuoyIcon
                  className="h-5 w-5 fill-black"
                  aria-hidden="true"
                />
              </span>
              {generateTranslatedText("submit", language)}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
