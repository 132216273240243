import Template from "../app/Template";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import { getGroupBySlug } from "../../slices/interest";
import { ChatBubbleLeftRightIcon, PencilIcon, UserGroupIcon, UserMinusIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { generateTranslatedText } from "../../utils/boilerplate";
import { getPostsByGroupSlug, PostState } from "../../slices/content";
import FeedFilmItem from "../layout/FeedFilmItem";
import Button from "../layout/Button";
import NoContent from "../layout/NoContent";
import CreateOrSearchHeader from "../landing/CreateOrSearchHeader";
import Spinner from "../layout/Spinner";
import { toggleGroupMembership } from "../../slices/auth";
import { addAlert } from "../../slices/alert";

export default function GroupBySlug() {
  const dispatch = useAppDispatch();

  // Get URL params
  const { group_slug: groupSlug } = useParams()

  // Get query params
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("query");

  // On page load
  useEffect(() => {
    dispatch(getGroupBySlug({ groupSlug }));
    dispatch(getPostsByGroupSlug({ groupSlug, pageNumber: 1, query: querySearch }));
  }, [dispatch, querySearch, groupSlug]);

  // Get app level state from redux store
  const {
    group: {
      data: groupData,
      loading: groupLoading,
    }
  } = useAppSelector((state) => state.interest);
  const {
    user,
    loading,
    waiting,
    isAuthenticated
  } = useAppSelector((state) => state.auth);
  const {
    posts: {
      data: postsData,
      loading: postsLoading,
      waiting: postsWaiting,
      pagination: postsPagination
    }
  } = useAppSelector((state) => state.content);


  // Component level state
  const [page, setPage] = useState(1);

  // Functions
  async function fetchPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getPostsByGroupSlug({ groupSlug, pageNumber, query }));
  }

  // Get component level state
  const language = "EN";
  return (
    <Template>

      <div className="grid grid-cols-1 gap-4 my-16">

        <div>
          {/* Group */}
          {groupLoading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : groupData === null ? <p>404</p> : (
            <>

              {/* Photos */}
              <div className="w-full">

                {/* Cover photo */}
                <img
                  src={groupData.cover_picture === null ? "/img/graphics/placeholder_group_cover_picture.svg" : groupData.cover_picture}
                  alt={groupData.name}
                  className="w-full h-64 object-cover rounded-lg z-10"
                />

                {/* Profile photo */}
                <div className="p-1 rounded-full w-36 h-36 absolute left-1/2 transform -translate-x-1/2 z-20">
                  <img
                    src={groupData.profile_picture === null ? "/img/graphics/placeholder_group_profile_picture.svg" : groupData.profile_picture}
                    alt={groupData.name}
                    className="w-32 h-32 object-cover rounded-full -mt-16 border-4 border-light/50 z-20"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 gap-y-12 mt-8">

                {/* Metadata */}
                <div className="flex flex-col items-center gap-y-2 mt-16">

                  {/* Name */}
                  <span className="flex gap-4 flex-row items-center">
                    <h1 className="text-xl font-bold text-white">
                      {groupData.name}
                    </h1>

                    {groupData.is_administrator && (
                      <a href={`/group/${groupData.slug}/edit`} className="group flex gap-x-2 items-center text-sm text-light/50 hover:text-warning rounded-md border border-light/50 hover:border-warning px-2 py-1 cursor-pointer">
                        <PencilIcon className="w-4 h-4 text-light/50 group-hover:text-warning" />
                        {generateTranslatedText("edit_group", language)}
                      </a>
                    )}

                    {!groupData.is_administrator && (
                      <>
                        {user?.profile.groups.map((x) => x[0]).includes(groupData.slug) ? (
                          <button
                            className="group flex gap-x-2 items-center text-sm text-light/50 hover:text-warning rounded-md border border-light/50 hover:border-warning px-2 py-1 cursor-pointer"
                            onClick={(e) => {
                              if (!isAuthenticated) {
                                dispatch(addAlert(generateTranslatedText("login_to_join_group", language), "warning"));
                                return;
                              }
                              dispatch(toggleGroupMembership({ groupId: groupData.id }))
                            }
                            }
                          >
                            {waiting ? (
                              <Spinner className="w-4 h-4" colour="warning" />
                            ) : (
                              <UserMinusIcon className="w-4 h-4 text-light/50 group-hover:text-warning" />
                            )}
                            {generateTranslatedText("leave_group", language)}
                          </button>
                        ) : (
                          <button
                            className="group flex gap-x-2 items-center text-sm font-semibold text-black rounded-md bg-warning hover:bg-warning-dark px-2 py-1 cursor-pointer"
                            onClick={(e) => {
                              if (!isAuthenticated) {
                                dispatch(addAlert(generateTranslatedText("login_to_join_group", language), "warning"));
                                return;
                              }
                              dispatch(toggleGroupMembership({ groupId: groupData.id }))
                            }
                            }
                          >
                            {waiting ? (
                              <Spinner className="w-4 h-4" colour="black" />
                            ) : (
                              <UserPlusIcon className="w-4 h-4 text-black" />
                            )}
                            {generateTranslatedText("join_group", language)}
                          </button>
                        )}
                      </>
                    )}

                  </span>

                  {/* Created at */}
                  <p className="text-sm text-secondary">
                    {`${generateTranslatedText("created_at", language)} ${groupData.created_date}`}
                  </p>

                  <div className="flex flex-row gap-4">

                    {/* Number of members */}
                    <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                      <UserGroupIcon className="w-5 h-5 text-warning" />
                      {`${groupData.num_members} ${groupData.num_members === 1 ? "Member" : "Members"}`}
                    </span>

                    {/* Number of comments */}
                    {/* <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                      <ChatBubbleOvalLeftIcon className="w-5 h-5 text-warning" />
                      {`${user.profile.num_comments} Comments`}
                    </span> */}

                    {/* Number of upvotes */}
                    {/* <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                      <StarIcon className="w-5 h-5 text-warning" />
                      {`${user.profile.num_upvotes}`}
                    </span> */}
                  </div>

                </div>

                {/* Create a post */}
                <CreateOrSearchHeader
                  searchHandler={fetchPostsByPageAndQuery}
                  searchHandlerArgs={[page]}
                  loading={loading}
                  showCreateQuestionButton={false}
                  showCreateGroupButton={false}
                  groupSlug={groupSlug}
                />

                {/* Posts */}
                {postsLoading ? (
                  <div className="flex justify-center items-center w-full my-20">
                    <LoadingBubbles />
                  </div>
                ) : postsData.length === 0 ? (
                  <div className="flex justify-center my-12">
                    <NoContent
                      text={generateTranslatedText("no_group_posts", language)}
                      subtext={generateTranslatedText("no_group_posts_subtext", language)}
                      icon={<ChatBubbleLeftRightIcon className="mx-auto h-12 w-12 text-warning animate-bounce" />}
                    />
                  </div>
                ) : postsData.map((post: PostState) => (
                  <FeedFilmItem post={post} key={post.id} />
                ))}

                {/* Load more content */}
                {postsPagination.has_next && (
                  <div className="flex justify-center items-center my-10">
                    {postsWaiting ? (
                      <div className="flex justify-center items-center w-full my-20">
                        <LoadingBubbles />
                      </div>
                    ) : (
                      <Button
                        bgColour="black hover:bg-white/10"
                        textColour="warning"
                        borderColour="warning"
                        className="items-center w-fit"
                        onClick={() => {
                          dispatch(getPostsByGroupSlug({ pageNumber: page + 1, groupSlug, query: querySearch }))
                          setPage(page + 1);
                        }}
                      >
                        <span className="flex items-center gap-x-1 text-warning">
                          {generateTranslatedText("load_more_content", language)}
                        </span>
                      </Button>
                    )}
                  </div>
                )}

              </div>

            </>
          )}

        </div>
      </div>

    </Template>
  );
}
