import { useState } from 'react';
import Button from './Button';

interface TabsProps {
  tabHeaders: Array<{ id: string, name: string, icon: any, count: number, showCount: boolean }>;
  tabContents: Array<{ id: string; content: React.ReactNode }>;
  onClickTab?: (e: React.MouseEvent<HTMLButtonElement>, args: any[]) => void;
}

export default function Tabs({
  tabHeaders,
  tabContents,
  onClickTab = () => { },
}: TabsProps) {

  // Component level state
  const [activeTab, setActiveTab] = useState(tabHeaders[0].id);

  return (
    <div>
      {/* Small viewport, show dropdown */}
      {/* <div className="sm:hidden px-1">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="col-span-full block w-full appearance-none rounded-md border-2 border-black bg-white px-3 py-2 text-black placeholder-secondary focus:border-primary focus:bg-light/40 focus:outline-none focus:ring-primary sm:text-sm"
          defaultValue={tabHeaders.find((val) => val.id === activeTab)?.name ?? ''}
          onChange={(e) => setActiveTab(e.target.value)}
        >
          {tabHeaders.map((tab) => (
            <option value={tab.id} key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div> */}

      {/* Large viewport, show tabs */}
      <div className="overflow-x-auto scrollbar-invisible w-full">
        <div className="border-none pb-3">
          <nav className="-mb-px flex justify-center space-x-6" aria-label="Tabs">
            {tabHeaders.map((tab, tabIdx) => (
              <Button
                key={`${tab.name}-${tabIdx}-tab`}
                bgColour={`${activeTab === tab.id ? 'warning hover:bg-warning-dark' : 'black-light'}`}
                textColour={`text-${activeTab === tab.id ? 'black' : 'white hover:text-warning'}`}
                // className={`${activeTab === tab.id ? 'bg-primary' : 'bg-white'} border-2 border-black box-shadow-black-5 button-pressed group inline-flex items-center border-b-2 py-2 px-1 rounded-md text-sm font-medium cursor-pointer w-fit`}
                aria-current={activeTab === tab.id ? 'page' : undefined}
                onClick={(e) => {
                  e.preventDefault();
                  onClickTab && onClickTab(e, [tab.id]);
                  setActiveTab(tab.id)
                }}
              >
                <tab.icon
                  className={`text-${activeTab === tab.id ? 'black' : 'white group-hover:text-warning'} -ml-0.5 mr-2 h-5 w-5`}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
                {tab.showCount && (
                  <span
                    className={`bg-transparent ml-3 hidden ring-1 ring-${activeTab === tab.id ? "black" : "light/20"} ring-inset rounded-full py-0.5 px-2.5 text-xs font-medium text-${activeTab === tab.id ? "black" : "white"} md:inline-block`}
                  >
                    {tab.count}
                  </span>
                )}
              </Button>
            ))}
          </nav>
        </div>
      </div>

      {/* Show active tab content */}
      <div className="mt-5">
        {tabContents.find((tab) => tab.id === activeTab)?.content || null}
      </div>
    </div>
  )
}