import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";


export interface NotificationState {
  id: string;
  created_date: string;
  updated_date: string | null;
  object_id: string;
  sender: string;
  sender_pic: string;
  sender_ref: string;
  is_broadcast: boolean;
  individual_recipient: string | null;
  category: [string, string];
  url: string;
  status: [string, string]
}


// Define a type for the slice state
export interface NotifState {
  data: NotificationState[];
  waiting: boolean;
  loading: boolean;
  errors: any[];
  options: { [key: string]: Array<string[]> };
  pagination: {
    has_previous: boolean;
    has_next: boolean;
    current_page: number;
    total_pages: number;
    total_objects: number;
  };
}

// Define the initial state using that type
const initialState: NotifState = {
  data: [],
  waiting: false,
  loading: false,
  errors: [],
  options: {},
  pagination: {
    has_previous: false,
    has_next: false,
    current_page: 1,
    total_pages: 1,
    total_objects: 0,
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get notifications
export const getNotifications = createAsyncThunk(
  "notification/get",
  async (
    obj: {
      pageNum: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNum.toString();
      const searchParams = new URLSearchParams(params).toString();
      const url = `api/notification${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Dismiss notification
export const dismissNotification = createAsyncThunk(
  "notification/put",
  async (
    obj: {
      notificationId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.notificationId,
      });

      const res = await axios.put(`api/notification`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get notifications
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.data.data;
        state.pagination = action.payload.data.pagination;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.loading = false;
        state.errors.push(action.error.message as string);
      })

      // Update notification
      .addCase(dismissNotification.pending, (state) => {
        state.loading = true;
      })
      .addCase(dismissNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.data = state.data.filter((notification) => notification.id !== action.payload.data.id);
      })
      .addCase(dismissNotification.rejected, (state, action) => {
        state.loading = false;
        state.errors.push(action.error.message as string);
      });
  },
});

export default notificationSlice.reducer;
