import { useEffect } from "react";
import { generateTranslatedText } from "../../utils/boilerplate";
import Card from "../layout/Card";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTopGroups } from "../../slices/interest";
import LoadingBubbles from "../layout/LoadingBubbles";

export default function TopGroups() {
  const dispatch = useAppDispatch();

  // On page load
  useEffect(() => {
    dispatch(getTopGroups({ pageNumber: 1 }));
  }, [dispatch]);

  // Get app level state from redux store
  const { groups: { data, loading } } = useAppSelector((state) => state.interest);

  // Constant variables
  const language = "EN";
  return (
    <Card className="w-full h-fit hidden sm:block">
      <div className="grid grid-cols-1 gap-4">

        {/* Header */}
        <h2 className="text-xl font-semibold text-white mb-2">{generateTranslatedText("top_groups", language)}</h2>

        {/* Groups */}
        <div className="grid grid-cols-1 divide-y divide-light/20">
          {loading ? (
            <div className="flex justify-center my-12">
              <LoadingBubbles />
            </div>
          ) : (
            data.map((group: any, index: number) => (
              <a key={index} href={`/group/${group.slug}`} className="group">
                <div className="flex items-center justify-between py-4 rounded-md hover:bg-white/5 px-4 py-1">
                  <div className="flex items-center gap-x-4">

                    {/* Profile picture */}
                    <img src={group.profile_picture === null ? "/img/graphics/placeholder_group_profile_picture.svg" : group.profile_picture} alt={group.name} className="w-10 h-10 rounded-full border-none ring ring-2 ring-light/50 group-hover:ring-warning ring-offset-2 ring-offset-black-light object-cover" />

                    {/* Name and number of members */}
                    <div className="flex flex-col">
                      <div className="text-secondary">
                        <p className="text-base font-normal text-white mr-1 group-hover:text-warning">{group.name}</p>
                      </div>
                      <span className="text-xs font-normal text-secondary">{`${group.num_members} ${group.num_members === 1 ? "member" : "members"}`}</span>
                    </div>
                  </div>
                </div>
              </a>
            ))
          )}
        </div>
      </div>
    </Card>
  );
}
