interface SpaceFillerProps {
  id?: string;
  className?: string;
  children: React.ReactNode;
  height?: string;
  verticalPosition?: string;
  flex?: boolean;
  horizontalPosition?: string;
}

export default function SpaceFiller({
  id = "",
  className = "",
  children,
  height = "full",
  verticalPosition = "center",
  horizontalPosition = "center",
}: SpaceFillerProps) {
  return (
    <div
      id={id}
      className={`flex h-${height} flex-1 justify-${horizontalPosition} items-${verticalPosition} ${className}`}
    >
      {children}
    </div>
  );
}