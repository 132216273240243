import { generateTranslatedText } from "../../utils/boilerplate";
import Button from "../layout/Button";
import {
  FireIcon as FireIconSolid,
  StarIcon as StarIconSolid,
  SparklesIcon as SparklesIconSolid,
  HeartIcon as HeartIconSolid
} from "@heroicons/react/24/solid";
import {
  FireIcon as FireIconOutline,
  StarIcon as StarIconOutline,
  SparklesIcon as SparklesIconOutline,
  HeartIcon as HeartIconOutline
} from "@heroicons/react/24/outline";

export default function LandingFilters({
  page = "/"
}: {
  page?: string;
}) {

  const language = "EN";
  return (
    <div className="grid grid-cols-1 xs:grid-cols-3 gap-4 my-4">

      {/* New */}
      <Button
        bgColour={`${page === "/new" ? "warning hover:bg-warning-dark" : "black-light hover:bg-white/10"}`}
        textColour="text-white"
        borderColour="none"
        className="items-center"
        onClick={() => {
          window.location.href = "/new"
        }}
      >
        <span className={`flex items-center gap-x-2 text-${page === "/new" ? "black" : "white group-hover:text-warning"}`}>
          {page === "/new" ? <SparklesIconSolid className="h-5 w-5 text-black" /> : <SparklesIconOutline className="h-5 w-5 text-white group-hover:text-warning" />}
          {generateTranslatedText("new", language)}
        </span>
      </Button>

      {/* Top voted */}
      <Button
        bgColour={`${page === "/top" ? "warning hover:bg-warning-dark" : "black-light hover:bg-white/10"}`}
        textColour="text-black"
        borderColour="none"
        className="items-center"
        onClick={() => {
          window.location.href = "/top"
        }}
      >
        <span className={`flex items-center gap-x-2 text-${page === "/top" ? "black" : "white group-hover:text-warning"}`}>
          {page === "/top" ? <StarIconSolid className="h-5 w-5 text-black" /> : <StarIconOutline className="h-5 w-5 text-white group-hover:text-warning" />}
          {generateTranslatedText("top", language)}
        </span>
      </Button>

      {/* Trending */}
      <Button
        bgColour={`${page === "/trending" ? "warning hover:bg-warning-dark" : "black-light hover:bg-white/10"}`}
        textColour="text-white"
        borderColour="none"
        className="items-center"
        onClick={() => {
          window.location.href = "/trending"
        }}
      >
        <span className={`flex items-center gap-x-2 text-${page === "/trending" ? "black" : "white group-hover:text-warning"}`}>
          {page === "/trending" ? <FireIconSolid className="h-5 w-5 text-black" /> : <FireIconOutline className="h-5 w-5 text-white group-hover:text-warning" />}
          {generateTranslatedText("trending", language)}
        </span>
      </Button>

      {/* For you */}
      {/* <Button
        bgColour={`${page === "/for-you" ? "warning hover:bg-warning-dark" : "black-light hover:bg-white/10"}`}
        textColour="text-white"
        borderColour="none"
        className="items-center"
        onClick={() => {
          window.location.href = "/for-you"
        }}
      >
        <span className={`flex items-center gap-x-2 text-${page === "/for-you" ? "black" : "white group-hover:text-warning"}`}>
          {page === "/for-you" ? <HeartIconSolid className="h-5 w-5 text-black" /> : <HeartIconOutline className="h-5 w-5 text-white group-hover:text-warning" />}
          {generateTranslatedText("for_you", language)}
        </span>
      </Button> */}
    </div>
  );
}
