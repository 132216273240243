import Navbar from "../landing/Navbar";
import Banner from "../layout/Banner";
import SpaceFiller from "../layout/SpaceFiller";

export default function NotFound() {
  return (
    <div className="h-screen flex flex-col bg-black">
      <Navbar />

      <SpaceFiller>
        <div className="flex flex-col max-w-7xl items-center justify-center">
          <div className="container text-center">
            <h1 className="text-9xl font-bold text-danger">404</h1>
            <h2 className="text-4xl font-bold text-white">Page not found</h2>
          </div>
        </div>
      </SpaceFiller>

      {/* Alert banner */}
      <Banner />
    </div>
  );
}
