import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useState } from "react";
import {
  deleteAccountPermanently,
  updateProfile,
} from "../../slices/auth";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import Button from "../layout/Button";
import Card from "../layout/Card";
import { addAlert } from "../../slices/alert";
import Header from "../layout/Header";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import { SparklesIcon, TrashIcon } from "@heroicons/react/24/outline";
import TextInput from "../forms/TextInput";


export default function Settings() {
  const dispatch = useAppDispatch();

  // App level state from redux store
  const {
    user,
    loading,
    isAuthenticated
  } = useAppSelector((state) => state.auth);

  // Component level state
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [updatePasswordData, setUpdatePasswordData] = useState({
    current_password: "",
    update_password: "",
    confirm_password: "",
  });

  // Functions
  async function confirmDeleteAccount(e: any) {
    e.preventDefault();
    setShowDeleteAccountModal(false);
    const res: any = await dispatch(deleteAccountPermanently());
    if (res.payload.status === 205) {
      dispatch(
        addAlert(
          generateTranslatedText("delete_account_permanently", language),
          "success"
        )
      );
    } else {
      dispatch(
        addAlert(
          generateTranslatedText("delete_account_permanently_error", language),
          "danger"
        )
      );
    }
  }

  async function triggerUpdatePassword(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_update_password", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const {
      current_password: currentPassword,
      update_password: updatePassword,
      confirm_password: confirmPassword,
    } = updatePasswordData;

    if (updatePassword !== "") {
      if (currentPassword === "") {
        dispatch(addAlert(generateTranslatedText("current_password_missing", language), "warning"));
        return;
      }
      if (updatePassword !== confirmPassword) {
        dispatch(addAlert(generateTranslatedText("update_passwords_do_not_match", language), "warning"));
        return;
      }
    }

    // Dispatch action
    const res: any = await dispatch(updateProfile({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      username: user.username,
      currentPassword: currentPassword,
      updatePassword: updatePassword,
      confirmPassword: confirmPassword,
      dateOfBirth: user.profile.date_of_birth,
      profilePicture: null,
      coverPicture: null,
      bio: user.profile.bio === null ? "" : user.profile.bio,
    }))
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("password_updated", language), "success"));
      setUpdatePasswordData({
        current_password: "",
        update_password: "",
        confirm_password: "",
      });
    } else {
      const error = res.payload.response.data;
      if (error.password !== undefined) {
        dispatch(addAlert(generateTranslatedText("update_password_current_wrong_error", language), "warning"));
      } else {
        dispatch(addAlert(generateTranslatedText("update_password_error", language), "warning"));
      }
    }
  }

  // Constant variables
  const language = "EN";
  return (
    <Template>

      {/* Delete account modal */}
      <Modal
        show={showDeleteAccountModal}
        setShow={setShowDeleteAccountModal}
        confirmText={generateTranslatedText("delete", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmDeleteAccount}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {generateTranslatedText("delete_account", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-sm font-normal text-secondary">
                {generateTranslatedText("delete_account_confirmation", language)}
              </p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Header */}
      <Header
        text={generateTranslatedText("settings", language)}
      />

      <div className="grid grid-cols-1 gap-4 my-8">

        {/* Loading spinner */}
        {loading || user === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <>
            {/* Update password */}
            <Card className="w-4/5 md:w-2/3 lg:w-1/2 mx-auto grid grid-cols-1 gap-y-6">

              {/* Current password */}
              <TextInput
                id="profile-current-password"
                type="password"
                label={generateTranslatedText("current_password", language)}
                placeholder={generateTranslatedText("current_password_placeholder", language)}
                onChange={(e) => setUpdatePasswordData({ ...updatePasswordData, current_password: e.target.value })}
                required={true}
                showLabel={true}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={updatePasswordData.current_password}
              />

              {/* New password */}
              <TextInput
                id="profile-update-password"
                type="password"
                label={generateTranslatedText("new_password", language)}
                placeholder={generateTranslatedText("new_password_placeholder", language)}
                onChange={(e) => setUpdatePasswordData({ ...updatePasswordData, update_password: e.target.value })}
                required={true}
                showLabel={true}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={updatePasswordData.update_password}
              />

              {/* Confirm new password */}
              <TextInput
                id="profile-confirm-password"
                type="password"
                label={generateTranslatedText("confirm_password", language)}
                placeholder={generateTranslatedText("confirm_password_placeholder", language)}
                onChange={(e) => setUpdatePasswordData({ ...updatePasswordData, confirm_password: e.target.value })}
                required={true}
                showLabel={true}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={updatePasswordData.confirm_password}
              />

              {/* Save button */}
              <Button
                bgColour="warning hover:bg-warning-dark"
                textColour="black"
                borderColour="black"
                onClick={triggerUpdatePassword}
              >
                <span className="flex items-center justify-center gap-x-2 text-black">
                  <SparklesIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                  Save
                </span>
              </Button>

            </Card>

            {/* Delete account */}
            <Card className="w-4/5 md:w-2/3 lg:w-1/2 mx-auto grid grid-cols-1 gap-y-6">

              {/* Danger zone */}
              <div className="grid grid-cols-1 gap-y-4">
                <label className="block text-xs font-bold text-white underline">
                  {generateTranslatedText("danger_zone", language)}
                </label>
                <div>
                  <Button
                    bgColour="transparent hover:bg-danger/20"
                    textColour="black"
                    borderColour="none"
                    onClick={() => setShowDeleteAccountModal(true)}
                  >
                    <span className="flex items-center gap-2 text-white">
                      <TrashIcon
                        className="h-5 w-5 text-danger"
                        aria-hidden="true"
                      />
                      {generateTranslatedText("delete_account", language)}
                    </span>
                  </Button>
                </div>
              </div>

            </Card>
          </>
        )}

      </div>
    </Template>
  );
}
