import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import {
  updatePasswordByRecovery,
  verifyPasswordRecoverySession,
} from "../../slices/auth";
import { generateTranslatedText } from "../../utils/boilerplate";
import { LifebuoyIcon, PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import Card from "../layout/Card";
import SpaceFiller from "../layout/SpaceFiller";
import ClickableLink from "../layout/ClickableLink";
import Button from "../layout/Button";
import LoadingBubbles from "../layout/LoadingBubbles";
import TextInput from "../forms/TextInput";

export default function ResetPasswordForm() {
  const dispatch = useAppDispatch();

  // App level state
  const language = "EN";

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // Get query parameters
  const profileId = searchParams.get("profile");
  const sessionId = searchParams.get("session");

  useEffect(() => {
    dispatch(verifyPasswordRecoverySession({ profileId, sessionId }));
  }, [dispatch, profileId, sessionId]);

  // Get app level state
  const {
    email: {
      recovery: { loading, verified },
    },
  } = useAppSelector((state) => state.auth);

  // Component level state
  const [state, setState] = useState({
    password: "",
    confirm_password: "",
  });

  // Functions
  async function submitNewPassword(e: any) {
    e.preventDefault();

    const { password, confirm_password: confirmPassword } = state;

    // Validate length
    if (password === "") {
      dispatch(
        addAlert(
          generateTranslatedText("missing_new_password", language),
          "danger"
        )
      );
      return;
    }
    if (confirmPassword === "") {
      dispatch(
        addAlert(
          generateTranslatedText("missing_confirm_password", language),
          "danger"
        )
      );
      return;
    }

    // Validate if passwords match
    if (password !== confirmPassword) {
      dispatch(
        addAlert(
          generateTranslatedText("password_mismatch", language),
          "danger"
        )
      );
      return;
    }

    // Submit passwords for update
    const res: any = await dispatch(
      updatePasswordByRecovery({
        profileId,
        password,
        confirmPassword,
        sessionId,
      })
    );
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("password_recovery_success", language),
          "success"
        )
      );
      navigate("/login");
      return;
    }
    if (res.payload.message !== undefined) {
      dispatch(
        addAlert(
          generateTranslatedText(res.payload.message, language),
          "danger"
        )
      );
      return;
    }
    dispatch(
      addAlert(
        generateTranslatedText("password_recovery_error", language),
        "danger"
      )
    );
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <SpaceFiller>
        {loading ? (
          <div className="flex justify-center items-center">
            <LoadingBubbles />
          </div>
        ) : !verified ? (
          <>
            {/* Card */}
            <Card
              colour="black-light"
              paddingX={8}
              paddingY={8}
              borderColour="warning"
              className=" w-full max-w-md space-y-8"
            >
              {/* Header */}
              <div>
                <a href="/">
                  <img
                    className="mx-auto h-12 w-auto rounded-lg"
                    src="/img/logo/logo_transparent.svg"
                    alt="FilmWaffle"
                  />
                </a>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                  {generateTranslatedText("link_expired", language)}
                </h2>
                <p className="mt-5 text-secondary text-center text-sm">
                  {generateTranslatedText("link_expired_subtext", language)}
                </p>
              </div>

              {/* Submit email again */}
              <ClickableLink
                href="/forgot-my-password"
                bgColour="warning hover:bg-warning-dark"
                textColour="text-black"
                className="group relative flex w-full justify-center"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <PaperAirplaneIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </span>
                {generateTranslatedText("submit_email_again", language)}
              </ClickableLink>
            </Card>
          </>
        ) : (
          <>
            {/* Card */}
            <Card
              colour="black-light"
              paddingX={8}
              paddingY={8}
              borderColour="warning"
              className=" w-full max-w-md space-y-8"
            >
              {/* Header */}
              <div>
                <a href="/">
                  <img
                    className="mx-auto h-12 w-auto rounded-lg"
                    src="/img/logo/logo_transparent.svg"
                    alt="FilmWaffle"
                  />
                </a>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                  {generateTranslatedText("reset_password", language)}
                </h2>
                <p className="mt-5 text-secondary text-center text-sm">
                  {generateTranslatedText("reset_password_subtext", language)}
                </p>
              </div>

              {/* Form */}
              <form
                className="mt-8 space-y-4 grid grid-cols-1 gap-4"
                onSubmit={(e) => submitNewPassword(e)}
              >
                {/* New password */}
                <TextInput
                  id="new_password"
                  type="password"
                  label={generateTranslatedText("new_password", language)}
                  labelColour="text-white"
                  required={true}
                  showLabel={true}
                  placeholder={generateTranslatedText(
                    "new_password_placeholder",
                    language
                  )}
                  onChange={(e) => setState({ ...state, password: e.target.value })}
                  bgColour="black-light"
                  textColour="white"
                  borderColour="light/50"
                  placeholderColour="light/50"
                  value={state.password}
                />

                {/* Confirm password */}
                <TextInput
                  id="confirm_password"
                  type="password"
                  label={generateTranslatedText("confirm_password", language)}
                  labelColour="text-white"
                  required={true}
                  showLabel={true}
                  placeholder={generateTranslatedText(
                    "confirm_password_placeholder",
                    language
                  )}
                  onChange={(e) => setState({ ...state, confirm_password: e.target.value })}
                  bgColour="black-light"
                  textColour="white"
                  borderColour="light/50"
                  placeholderColour="light/50"
                  value={state.confirm_password}
                />

                <Button
                  type="submit"
                  rounded="md"
                  bgColour="warning hover:bg-warning-dark"
                  border="none"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LifebuoyIcon
                      className="h-5 w-5 text-black"
                      aria-hidden="true"
                    />
                  </span>
                  {generateTranslatedText("submit", language)}
                </Button>
              </form>
            </Card>
          </>
        )}
      </SpaceFiller>
    </div>
  );
}
