import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

// Define a type for the slice state
export interface Message {
  id: string;
  message: string;
  status: string;
  timeout: number;
}

export interface Point {
  id: string;
  message: string;
  status: string;
  timeout: number;
}

export interface AlertState {
  message: Array<Message>;
  points: Array<Point>;
}

// Define the initial state using that type
const initialState: AlertState = {
  message: [],
  points: []
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlert: {
      prepare(message: string, status: string, timeout: number = 3000) {
        const id = uuidv4();
        return { payload: { id, message, status, timeout } };
      },
      reducer(state, action: PayloadAction<Message>) {
        state.message.push(action.payload);
      },
    },
    removeAlert(state, action) {
      const remainingAlerts = state.message.filter(
        (alert) => alert.id !== action.payload
      );
      state.message = remainingAlerts;
      return state;
    },
    addPoints: {
      prepare(message: string, status: string, timeout: number = 3500) {
        const id = uuidv4();
        return { payload: { id, message, status, timeout } };
      },
      reducer(state, action: PayloadAction<Point>) {
        state.points.push(action.payload);
      },
    },
    removePoints(state, action) {
      const remainingPoints = state.points.filter(
        (point) => point.id !== action.payload
      );
      state.points = remainingPoints;
      return state;
    }
  },
});

export const { addAlert, removeAlert, addPoints, removePoints } = alertSlice.actions;
export default alertSlice.reducer;
