import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { removeAlert } from "../../slices/alert";
import { Transition } from "@headlessui/react";

export default function Alert({
  id,
  status,
  message,
  timeout,
}: {
  id: string;
  status: string;
  message: string;
  timeout: number;
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);

  function getTextColour() {
    if (status === "warning") return "black";
    return "black";
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, timeout);

    return () => {
      clearTimeout(timeId);
    };
  }, [timeout]);

  function deleteAlert() {
    dispatch(removeAlert(id));
    setShow(false);
  }

  return (
    <Transition
      show={show}
      enter="transform transition duration-500 ease-in-out"
      enterFrom="translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition duration-500 ease-in-out"
      leaveFrom="-translate-y-0"
      leaveTo="translate-y-full"
    >
      <div className="fixed inset-x-0 bottom-0 z-50" key={id}>
        <div className="w-full border-t-2 border-black">
          <div className={`bg-${status} p-2`}>
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-transparent p-2">
                  <MegaphoneIcon
                    className={`h-6 w-6 text-${getTextColour()}`}
                    aria-hidden="true"
                  />
                </span>
                <p
                  className={`ml-3 truncate font-medium text-${getTextColour()}`}
                >
                  <span className="md:hidden">{message}</span>
                  <span className="hidden md:inline">{message}</span>
                </p>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                <button
                  type="button"
                  className={`-mr-1 flex rounded-md p-2 hover:bg-slate-100/10 focus:outline-none focus:ring-2 focus:ring-${getTextColour()}`}
                  onClick={(e: any) => deleteAlert()}
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className={`h-6 w-6 text-${getTextColour()}`}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
