import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';

export default function SearchInputWithIcon({
  id,
  name,
  placeholder,
  bgColour = "black",
  textColour = "white",
  borderColour = "white",
  placeholderColour = "light",
  widthClass = "w-96",
  onSubmit = () => { },
  onSubmitArgs = [],
  loading
}: {
  id: string;
  name: string;
  placeholder: string;
  bgColour?: string;
  textColour?: string;
  borderColour?: string;
  placeholderColour?: string;
  widthClass?: string;
  onSubmit: (e: any, args: any[]) => void;
  onSubmitArgs: any[];
  loading: boolean;
}) {

  // Add event listener to input field for when Enter is pressed
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  function handleSubmit(e: any) {
    onSubmit(e, [...onSubmitArgs, searchValue]);

    // Clear input field
    const inputField = document.getElementById(`${id}`) as HTMLInputElement;
    inputField.value = "";
  }

  // Set component level state
  const [searchValue, setSearchValue] = useState("");
  return (
    <div>
      <div className={`grid grid-cols-1 ${widthClass}`}>
        <input
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          className={`col-start-1 row-start-1 block w-full rounded-full bg-${bgColour} py-1.5 pl-3 pr-10 text-base text-${textColour} placeholder:text-${placeholderColour} border-1 border-${borderColour} focus:border-2 focus:border-warning ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <MagnifyingGlassIcon
          aria-hidden="true"
          className={`${!loading && "cursor-pointer"} col-start-1 row-start-1 mr-3 size-5 self-center justify-self-end text-warning sm:size-4 ${loading && "animate-pulse"}`}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}
