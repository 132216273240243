import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createPost, getNewPosts, getTopVotedPosts, getTrendingPosts } from "../../slices/content";
import { generateTranslatedText } from "../../utils/boilerplate";
import { BellIcon as BellIconOutline, HomeIcon, MagnifyingGlassIcon, PencilSquareIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { BellIcon as BellIconSolid } from "@heroicons/react/24/solid";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import SearchInputWithIcon from "../forms/SearchInputWithIcon";
import { addAlert } from "../../slices/alert";
import { resetImageUploads } from "../../slices/uploads";
import TextInput from "../forms/TextInput";
import TextArea from "../forms/TextArea";
import UploadImageDropzone from "../forms/UploadImageDropzone";
import Dropdown from "../forms/Dropdown";
import SwitchToggle from "../forms/SwitchToggle";

export default function MobileBottomToolbar({ pageNumber, pageName, loading }: { pageNumber: number, pageName: string, loading: boolean }) {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const { images } = useAppSelector((state) => state.uploads);

  // Get component level state
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [createPostContent, setCreatePostContent] = useState({
    placeholder: "",
    group: "",
    title: "",
    body: "",
    image: {
      source: "",
      data: ""
    },
    type: "post"
  });

  // Functions
  async function fetchTopVotedPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getTopVotedPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  async function fetchTrendingPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getTrendingPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  async function fetchNewPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getNewPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  async function fetchForYouPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getTrendingPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  async function confirmCreatePost(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const { title, body, group, type } = createPostContent;
    if (title === "") {
      dispatch(addAlert(generateTranslatedText("missing_title_post", language), "warning"));
      return
    }
    if (body === "" && type === "post") {
      dispatch(addAlert(generateTranslatedText("missing_body_post", language), "warning"));
      return
    }

    // Get processed images from redux store
    let imageData: any = null;
    const processedImages = Object.values(images.data).filter((img) => img.success);
    if (processedImages.length > 0) {
      imageData = {
        id: processedImages[0].id,
        source: "processed",
        data: processedImages[0].data,
      };
    }

    // Dispatch action
    setShowCreatePost(false);
    const res: any = await dispatch(createPost({
      title,
      body,
      image: imageData,
      group: group === "" ? null : group,
    }))
    if (res.payload.status === 201) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("post_created", language), "success"));
    } else {
      setShowCreatePost(true);
      dispatch(addAlert(generateTranslatedText("post_created_error", language), "error"));
    }
  }

  // Constant variables
  const language = "EN";
  return (
    <>

      {/* Mobile search query modal */}
      <Modal
        show={showSearchModal}
        setShow={setShowSearchModal}
        confirmText=""
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={() => { }}
        showActionButtons={false}
        showTriggerButton={false}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <div className="my-4 grid grid-cols-1 gap-y-8">

              {/* Search for posts */}
              <SearchInputWithIcon
                id="search-posts"
                name="search-posts"
                placeholder={generateTranslatedText("search_topics", language)}
                bgColour="black"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                onSubmit={
                  pageName === "top" ? fetchTopVotedPostsByPageAndQuery :
                    pageName === "trending" ? fetchTrendingPostsByPageAndQuery :
                      pageName === "new" ? fetchNewPostsByPageAndQuery :
                        pageName === "for-you" ? fetchForYouPostsByPageAndQuery :
                          () => { }
                }
                onSubmitArgs={[pageNumber]}
                loading={loading}
              />

            </div>
          </div>
        </div>
      </Modal>

      {/* Mobile Create post modal */}
      <Modal
        show={showCreatePost}
        setShow={setShowCreatePost}
        confirmText={generateTranslatedText("create", language)}
        cancelText={generateTranslatedText("close", language)}
        confirmHandler={confirmCreatePost}
        showActionButtons={true}
        showTriggerButton={true}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
              {createPostContent.type === "post" ? generateTranslatedText("create_a_post", language) : generateTranslatedText("ask_a_question", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">

              <div className="flex justify-center items-center gap-x-2">
                <p className="text-sm font-semibold text-white">Question</p>
                <SwitchToggle
                  id="post-type"
                  label=""
                  showLabel={false}
                  checked={createPostContent.type === "post"}
                  onChange={(e) => setCreatePostContent({ ...createPostContent, type: createPostContent.type === "post" ? "question" : "post" })}
                  icon={createPostContent.type === "post" ? <PencilSquareIcon className="h-3 w-3 text-black" /> : <QuestionMarkCircleIcon className="h-3 w-3 text-black" />}
                />
                <p className="text-sm font-semibold text-white">Post</p>

              </div>

              {/* Group */}
              <Dropdown
                id="post-group-dropdown"
                label=""
                showLabel={false}
                required={false}
                onChange={(e) => setCreatePostContent({ ...createPostContent, group: e.target.value })}
                value={createPostContent.group}
              >
                <option value="">Select a group</option>
                {user?.profile.groups.map((opt, idx) => (
                  <option key={idx} value={opt[0]}>{opt[1]}</option>
                ))}
              </Dropdown>

              {/* Title */}
              <TextInput
                id="post-title"
                type="text"
                placeholder={createPostContent.type === "post" ? generateTranslatedText("title", language) : generateTranslatedText("ask_a_question", language)}
                onChange={(e) => setCreatePostContent({ ...createPostContent, title: e.target.value })}
                required={true}
                showLabel={false}
                bgColour="black-light"
                textColour="white"
                borderColour="light/50"
                placeholderColour="light/50"
                widthClass="w-full"
                value={createPostContent.title}
              />

              {/* Body */}
              {createPostContent.type === "post" && (
                <TextArea
                  id="post-title"
                  placeholder={createPostContent.placeholder}
                  onChange={(e) => setCreatePostContent({ ...createPostContent, body: e.target.value })}
                  required={true}
                  showLabel={false}
                  bgColour="black-light"
                  textColour="white"
                  borderColour="light/50"
                  placeholderColour="light/50"
                  widthClass="w-full"
                  rows={5}
                  value={createPostContent.body}
                />
              )}

              {/* Image upload */}
              <UploadImageDropzone
                id="post-image"
                className="w-full"
                limit={1}
                savedImages={[]}
                bgColour="black-light"
                textColour="light/50"
                borderColour="light/50"
              />

            </div>
          </div>
        </div>
      </Modal>

      {/* Mobile toolbar */}
      <div className="sm:hidden fixed w-full bottom-0 left-0 bg-black border-t-2 border-warning h-20 z-30 flex items-center">
        <div className="flex flex-row w-full justify-around items-center">

          {/* Home */}
          <a
            href="/"
            className=""
          >
            <HomeIcon className="h-6 w-6 text-white" />
          </a>

          {/* Search tooltip */}
          <button
            className=""
            onClick={(e) => {
              setShowSearchModal(true);
            }}
          >
            <MagnifyingGlassIcon className="h-6 w-6 text-white" />
          </button>

          {/* Create post */}
          <button
            className="rounded-full bg-warning p-2"
            onClick={() => {
              if (!isAuthenticated || !user) {
                dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                return
              }
              dispatch(resetImageUploads())
              setCreatePostContent({
                group: "",
                title: "",
                placeholder: generateTranslatedText("create_post_placeholder", language),
                body: "",
                image: {
                  source: "",
                  data: ""
                },
                type: "post"
              })
              setShowCreatePost(true)
            }}
          >
            <PencilSquareIcon className="h-6 w-6 text-black" />
          </button>

          {/* Notifications */}
          {user !== null && (
            <a
              href="/notifications"
              className=""
            >
              {user.profile.num_notifications > 0 ? (
                <BellIconSolid className={`h-6 w-6 text-warning animate-bounce`} />
              ) : (
                <BellIconOutline className={`h-6 w-6 text-white`} />
              )}
            </a>
          )}

          {/* Profile */}
          {user !== null && (
            <a
              type="button"
              href="/profile"
            >
              <img className="h-8 w-8 object-cover rounded-full border-none ring ring-2 ring-light/50 hover:ring-warning ring-offset-2 ring-offset-black-light" src={user.profile.profile_picture || "/img/graphics/placeholder_profile_picture.svg"} alt={user.username} />
            </a>
          )}

        </div>
      </div>

    </>
  );
}
