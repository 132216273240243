import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";

import Banner from "../layout/Banner";
import { useNavigate } from "react-router-dom";
import Card from "../layout/Card";

import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { sendVerificationEmail } from "../../slices/auth";
import { addAlert } from "../../slices/alert";
import { generateTranslatedText } from "../../utils/boilerplate";
import TextInput from "../forms/TextInput";
import SpaceFiller from "../layout/SpaceFiller";
import Button from "../layout/Button";
import LoadingBubbles from "../layout/LoadingBubbles";

export default function Verification() {
  // App level state
  const { user, loading } = useAppSelector((state) => state.auth);
  const language = "EN";

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.profile.is_verified) navigate("/");
  }, [navigate, user?.profile.is_verified]);

  const [updateFormData, setUpdateFormData] = useState<any>({
    resend_email: "",
  });

  async function resendConfirmationEmail(e: any) {
    e.preventDefault();
    const { resend_email: resendEmail } = updateFormData;
    if (!resendEmail || resendEmail.length === 0) {
      dispatch(
        addAlert(
          generateTranslatedText("email_required", language),
          "warning",
          4000
        )
      );
      return;
    }
    const res: any = await dispatch(
      sendVerificationEmail({ email: resendEmail })
    );
    if (res.payload.status === 200) {
      dispatch(
        addAlert(
          generateTranslatedText("verification_email_sent", language),
          "success"
        )
      );
    } else {
      dispatch(
        addAlert(
          generateTranslatedText("verification_email_sent_error", language),
          "danger",
          5000
        )
      );
    }
  }

  return (
    <div className="w-full h-screen overflow-y-scroll bg-black">
      <SpaceFiller>
        {loading ? (
          <div className="flex justify-center items-center">
            <LoadingBubbles />
          </div>
        ) : (
          <>
            {/* Card */}
            <Card
              colour="black-light"
              paddingX={8}
              paddingY={8}
              borderColour="warning"
              className=" w-full max-w-md space-y-8"
            >
              {/* Header */}
              <div>
                <a href="/">
                  <img
                    className="mx-auto h-12 w-auto rounded-lg"
                    src="/img/logo/logo_transparent.svg"
                    alt="FilmWaffle"
                  />
                </a>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                  {generateTranslatedText("verification_title", language)}
                </h2>
                <p className="mt-5 text-secondary text-center text-sm">
                  {generateTranslatedText("verification_subtext", language)}
                </p>
              </div>

              <p className="text-secondary text-center text-sm">
                <span className="font-bold text-white hover:text-warning">
                  {generateTranslatedText(
                    "email_verification_pre_text",
                    language
                  )}
                </span>{" "}
                {generateTranslatedText("email_verification_resend", language)}
              </p>

              {/* Form */}
              <form
                className="mt-8 space-y-6"
                onSubmit={(e) => resendConfirmationEmail(e)}
              >
                {/* Email */}
                <TextInput
                  id="resend_email"
                  type="text"
                  label={generateTranslatedText("resend_email", language)}
                  labelColour="text-white"
                  required={true}
                  showLabel={false}
                  placeholder={generateTranslatedText(
                    "email_resend_placeholder",
                    language
                  )}
                  onChange={(e) => setUpdateFormData({ ...updateFormData, resend_email: e.target.value })}
                  bgColour="black-light"
                  textColour="white"
                  borderColour="light/50"
                  placeholderColour="light/50"
                  value={updateFormData.resend_email}
                />

                {/* Submit */}
                <div>
                  <Button
                    bgColour="warning hover:bg-warning-dark"
                    textColour="black"
                    borderColour="black"
                    className="text-black"
                  >
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <PaperAirplaneIcon
                        className="h-5 w-5 fill-black"
                        aria-hidden="true"
                      />
                    </span>
                    {generateTranslatedText("submit", language)}
                  </Button>
                </div>
              </form>
            </Card>
          </>
        )}
      </SpaceFiller>

      {/* Alert banner */}
      <Banner />
    </div>
  );
}
