import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";


export interface AdState {
  id: string;
  order: number;
  category: [string, string];
  title: string;
  description: string;
  image: string;
  status: [string, string];
}

// Define a type for the slice state
export interface AdvertisementState {
  banner: {
    data: AdState[];
    loading: boolean;
    errors: any[];
  },
}

// Define the initial state using that type
const initialState: AdvertisementState = {
  banner: {
    data: [],
    loading: false,
    errors: [],
  }
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get Banner Ads
export const getBannerAds = createAsyncThunk(
  "advertisement/banner/get",
  async (
    args,
    { getState, rejectWithValue }
  ) => {
    try {

      // Build endpoint URL
      const url = `/api/advertisement?category=Banner`;

      // Send request
      const res = await axios.get(url);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Banner Ads
      .addCase(getBannerAds.pending, (state) => {
        state.banner.loading = true;
      })
      .addCase(getBannerAds.fulfilled, (state, action) => {
        state.banner.loading = false;
        state.banner.data = action.payload.data;
      })
      .addCase(getBannerAds.rejected, (state, action) => {
        state.banner.loading = false;
        state.banner.errors.push(action.payload as string);
      });

  },
});

export default advertisementSlice.reducer;
