import Banner from "../layout/Banner";
import SpaceFiller from "../layout/SpaceFiller";
import Navbar from "../landing/Navbar";
import Footer from "../footer/Footer";

export default function Template({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <>
      <Navbar />
      <SpaceFiller className="bg-black" verticalPosition="start">
        <main className="w-screen px-4 sm:px-8 md:px-12 lg:px-26">{children}</main>

        {/* Alert banner */}
        <Banner />
      </SpaceFiller>

      {/* Footer */}
      <Footer />
    </>
  );
}
