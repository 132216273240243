interface ButtonProps {
  id?: string;
  type?: "button" | "submit" | "reset";
  rounded?: string;
  bgColour?: string;
  textColour?: string;
  border?: string;
  borderColour?: string;
  className?: string;
  children: React.ReactNode;
  onClick?: (e: any, onClickArgs: any) => void;
  onClickArgs?: any[];
  disabled?: boolean;
  paddingY?: string;
  paddingX?: string;
}

export default function Button({
  id = "",
  type = "submit",
  rounded = "md",
  bgColour = "warning",
  textColour = "text-black",
  border = "2",
  borderColour = "black",
  className = "",
  children,
  onClick = () => { },
  onClickArgs = [],
  disabled = false,
  paddingY = "2",
  paddingX = "4",
}: ButtonProps) {
  return (
    <button
      id={id}
      type={type}
      className={`group relative flex w-full justify-center rounded-${rounded} border-${border} border-${borderColour} bg-${bgColour} py-${paddingY} px-${paddingX} text-sm font-semibold ${textColour} ${className}`}
      onClick={(e) => (onClick === undefined ? null : onClick(e, onClickArgs))}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
