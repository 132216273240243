import { PaperAirplaneIcon as PaperAirplaneIconOutline } from '@heroicons/react/24/outline';
import { PaperAirplaneIcon as PaperAirplaneIconSolid } from '@heroicons/react/24/solid';

export default function TextInputWithIcon({
  id,
  name,
  placeholder,
  bgColour = "black",
  textColour = "white",
  borderColour = "white",
  placeholderColour = "light",
  widthClass = "w-96",
  onChange = () => { },
  onClick = () => { },
  loading
}: {
  id: string;
  name: string;
  placeholder: string;
  bgColour?: string;
  textColour?: string;
  borderColour?: string;
  placeholderColour?: string;
  widthClass?: string;
  onChange?: (e: any) => void;
  onClick?: () => void;
  loading: boolean;
}) {

  // Add event listener to input field for when Enter is pressed
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  }

  function handleSubmit() {
    onClick();
    // Clear input field
    const inputField = document.getElementById(`${id}-text-input`) as HTMLInputElement;
    inputField.value = "";
  }

  return (
    <div>
      <div className={`grid grid-cols-1 ${widthClass}`}>
        <input
          id={`${id}-text-input`}
          name={name}
          type="text"
          placeholder={placeholder}
          className={`col-start-1 row-start-1 block w-full rounded-full bg-${bgColour} py-1.5 pl-3 pr-10 text-base text-${textColour} placeholder:text-${placeholderColour} border-1 border-${borderColour} focus:border-2 focus:border-warning ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
          onChange={onChange}
          onKeyDown={handleKeyPress}
        />
        {loading ? (
          <PaperAirplaneIconSolid
            aria-hidden="true"
            className="col-start-1 row-start-1 mr-3 size-5 self-center justify-self-end text-warning sm:size-4 animate-spin"
            onClick={handleSubmit}
          />
        ) : (
          <PaperAirplaneIconOutline
            aria-hidden="true"
            className="cursor-pointer col-start-1 row-start-1 mr-3 size-5 self-center justify-self-end text-warning sm:size-4"
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  )
}
