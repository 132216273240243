import Template from "../app/Template";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getPostBySlug } from "../../slices/content";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import FeedFilmItem from "../layout/FeedFilmItem";

export default function PostBySlug() {
  const dispatch = useAppDispatch();

  // Get query params
  const [searchParams] = useSearchParams();
  const queryComment = searchParams.get("comment");

  // Get URL params
  const { post_slug: postSlug } = useParams()

  // On page load
  useEffect(() => {
    dispatch(getPostBySlug({ postSlug }));
  }, [dispatch, postSlug]);

  // Get app level state from redux store
  const {
    post: {
      data: postData,
      loading: postLoading
    }
  } = useAppSelector((state) => state.content);

  return (
    <Template>

      <div className="grid grid-cols-1 gap-4 my-16">

        {/* Posts content */}
        <div>

          {/* Feed */}
          {postLoading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : postData === null ? <p>404</p> : (
            <div className="rounded-md bg-black-light p-4">
              <FeedFilmItem post={postData} key={postData.id} showFirstPageOfComments={true} highlightedComment={queryComment} />
            </div>
          )}

        </div>
      </div>

    </Template>
  );
}
