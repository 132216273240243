import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { SparklesIcon } from "@heroicons/react/24/solid";
import Button from "../layout/Button";
import Card from "../layout/Card";
import TextInput from "../forms/TextInput";
import TextArea from "../forms/TextArea";
import UploadImageDropzone from "../forms/UploadImageDropzone";
import { addAlert } from "../../slices/alert";
import { resetImageUploads } from "../../slices/uploads";
import { useParams } from "react-router-dom";
import { getGroupBySlug, getInterestGroupCategories, updateGroup } from "../../slices/interest";
import Dropdown from "../forms/Dropdown";
import Header from "../layout/Header";
import Breadcrumbs from "../layout/Breadcrumbs";

interface UpdateGroupData {
  name: string;
  description: string;
  category: string;
  subcategory: string[];
  profile_picture: string | null;
  cover_picture: string | null;
}

export default function EditGroup() {
  const dispatch = useAppDispatch();

  // Get URL params
  const { group_slug: groupSlug } = useParams()

  useEffect(() => {
    dispatch(getGroupBySlug({ groupSlug }));
    dispatch(getInterestGroupCategories())
  }, [dispatch, groupSlug]);

  // App level state from redux store
  const {
    group: {
      data: groupData,
      loading: groupLoading
    },
    groups: {
      loading: groupsLoading,
      options
    }
  } = useAppSelector((state) => state.interest);
  const {
    user,
    isAuthenticated
  } = useAppSelector((state) => state.auth);
  const { images } = useAppSelector((state) => state.uploads);

  // Component level state
  const [updateFormData, setUpdateFormData] = useState<UpdateGroupData>({
    name: "",
    description: "",
    category: "",
    subcategory: [],
    profile_picture: null,
    cover_picture: null,
  });

  // Functions
  async function saveGroup(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    if (groupData === null) {
      dispatch(addAlert(generateTranslatedText("group_not_found", language), "warning"));
      return
    }

    const {
      name,
      description,
      category,
    } = updateFormData;

    // Get processed images from redux store
    let imageData: any = { cover: null, profile: null };
    if ("group-profile-picture" in images.data) {
      imageData.profile = {
        id: "group-profile-picture",
        source: "processed",
        data: images.data["group-profile-picture"].data,
      };
    }
    if ("group-cover-picture" in images.data) {
      imageData.cover = {
        id: "group-cover-picture",
        source: "processed",
        data: images.data["group-cover-picture"].data,
      };
    }

    let chosenCategory = null;
    if (category === "") {
      if (groupData.category !== null) {
        chosenCategory = groupData.category[0];
      }
    } else {
      chosenCategory = category;
    }

    // Dispatch action
    const res: any = await dispatch(updateGroup({
      groupId: groupData.id,
      administrators: groupData.administrators.map((admin: any) => admin[0]),
      name: name === "" ? groupData.name : name,
      description: description === "" ? groupData.description : description,
      category: chosenCategory,
      subcategory: groupData.subcategory.map((subcat: any) => subcat[0]),
      profile_picture: imageData.profile,
      cover_picture: imageData.cover,
      status: "A"
    }))
    if (res.payload.status === 200) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("group_updated", language), "success"));

      // Reload with new slug if different from existing after 2 seconds
      const newSlug = res.payload.data.slug;
      if (newSlug !== groupSlug) {
        setTimeout(() => {
          window.location.href = `/group/${newSlug}/edit`;
        }, 2000);
      }
    } else {
      dispatch(addAlert(generateTranslatedText("group_updated_error", language), "warning"));
    }
  }

  // Constant variables
  const language = "EN";
  const dropdownOptionsCategory = options.category === undefined ? [] : options.category.map((opt) => [opt.id, opt.name]);
  return (
    <Template>

      {/* Header */}
      <Header
        text={generateTranslatedText("edit_group", language)}
        breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: `/group/${groupSlug}`, text: "← Back to Group" }]} />}
      />

      <div className="grid grid-cols-1 gap-4 my-8">

        {/* Loading spinner */}
        {groupLoading || groupData === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <Card className="w-4/5 md:w-2/3 lg:w-1/2 mx-auto grid grid-cols-1 gap-y-6">

            {/* Profile picture */}
            <UploadImageDropzone
              id="group-profile-picture"
              label={generateTranslatedText("profile_picture", language)}
              className="w-full"
              limit={1}
              savedImages={groupData.profile_picture === null ? [] : [{ id: "saved-group-profile-picture", data: groupData.profile_picture }]}
              bgColour="black-light"
              textColour="light/50"
              borderColour="light/50"
            />

            {/* Cover photo */}
            <UploadImageDropzone
              id="group-cover-picture"
              label={generateTranslatedText("cover_picture", language)}
              className="w-full"
              limit={1}
              savedImages={groupData.cover_picture === null ? [] : [{ id: "saved-group-cover-picture", data: groupData.cover_picture }]}
              bgColour="black-light"
              textColour="light/50"
              borderColour="light/50"
            />

            {/* Name */}
            <TextInput
              id="group-name"
              type="text"
              label={generateTranslatedText("name", language)}
              placeholder={groupData.name}
              onChange={(e) => setUpdateFormData({ ...updateFormData, name: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={updateFormData.name}
            />

            {/* Description */}
            <TextArea
              id="group-description"
              placeholder={groupData.description}
              onChange={(e) => setUpdateFormData({ ...updateFormData, description: e.target.value })}
              required={true}
              label={generateTranslatedText("description", language)}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              rows={3}
              value={updateFormData.description}
            />

            {/* Category */}
            {groupsLoading ? (
              <div className="flex justify-center">
                <LoadingBubbles />
              </div>
            ) : (
              <Dropdown
                id="group-category-dropdown"
                label={generateTranslatedText("category", language)}
                showLabel={true}
                required={true}
                onChange={(e) => setUpdateFormData({ ...updateFormData, category: e.target.value })}
                value={updateFormData.category === "" ? groupData.category === null ? "" : groupData.category[0] : updateFormData.category}
              >
                <option value="">Select a category</option>
                {dropdownOptionsCategory.map((opt, idx) => (
                  <option key={idx} value={opt[0]}>{opt[1]}</option>
                ))}
              </Dropdown>
            )}

            {/* Last name */}
            {/* <TextInput
              id="profile-last-name"
              type="text"
              label={generateTranslatedText("last_name", language)}
              placeholder={generateTranslatedText("last_name_placeholder", language)}
              onChange={(e) => setUpdateFormData({ ...updateFormData, last_name: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={updateFormData.last_name}
            /> */}

            {/* Email */}
            {/* <TextInput
              id="profile-email"
              type="email"
              label={generateTranslatedText("email", language)}
              placeholder={generateTranslatedText("email_placeholder", language)}
              onChange={(e) => setUpdateFormData({ ...updateFormData, email: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={user.email}
              disabled={true}
            /> */}

            {/* Username */}
            {/* <TextInput
              id="profile-username"
              type="text"
              label={generateTranslatedText("username", language)}
              placeholder={generateTranslatedText("username_placeholder", language)}
              onChange={(e) => setUpdateFormData({ ...updateFormData, username: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              value={user.username}
              disabled={true}
            /> */}

            {/* Bio */}
            {/* <TextArea
              id="post-title"
              label={generateTranslatedText("bio", language)}
              placeholder={generateTranslatedText("bio_placeholder", language)}
              onChange={(e) => setUpdateFormData({ ...updateFormData, bio: e.target.value })}
              required={true}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              rows={5}
              value={updateFormData.bio}
            /> */}

            {/* Save button */}
            <Button
              bgColour="warning hover:bg-warning-dark"
              textColour="black"
              borderColour="black"
              onClick={saveGroup}
            >
              <span className="flex items-center justify-center gap-x-2 text-black">
                <SparklesIcon className="h-6 w-6 mr-2" aria-hidden="true" />
                Save
              </span>
            </Button>

          </Card>
        )}

      </div>
    </Template>
  );
}
