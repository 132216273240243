interface LinkProps {
  href: string;
  children: React.ReactNode;
  textColour?: string;
  bgColour?: string;
  bgHoverColour?: string;
  borderColour?: string;
  className?: string;
  shadow?: string;
  onClick?: (e: any, args: any[]) => void;
  onClickArgs?: any[];
  roundedClass?: string;
}

export default function ClickableLink({
  href,
  children,
  textColour = "text-white",
  bgColour = "black",
  bgHoverColour = "black-light",
  borderColour = "black",
  className = "",
  shadow = "none",
  onClick = () => { },
  onClickArgs = [],
  roundedClass = "md",
}: LinkProps) {
  return (
    <a
      href={href}
      className={`rounded-${roundedClass} border border-${borderColour} bg-${bgColour} px-3 py-2 text-sm font-semibold ${textColour} shadow-${shadow} hover:bg-${bgHoverColour} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${bgColour}-dark ${className}`}
      onClick={(e) =>
        onClick === undefined
          ? null
          : onClick(e, onClickArgs === undefined ? [] : onClickArgs)
      }
    >
      {children}
    </a>
  );
}