interface DropdownProps {
  id: string;
  className?: string;
  label?: string;
  showLabel?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  onChange?: (e: any) => void;
  value: string;
  bgColour?: string;
  textColour?: string;
  borderColour?: string;
  placeholderColour?: string;
}

export default function Dropdown({
  id,
  className = "col-span-full",
  label,
  showLabel,
  required,
  children,
  onChange = () => { },
  value,
  bgColour = "black-light",
  textColour = "white",
  borderColour = "light/50",
  placeholderColour = "light/50"
}: DropdownProps) {
  return (
    <div>
      {showLabel ? (
        <label
          htmlFor={id}
          className="mb-3 block text-sm font-medium text-white text-left"
        >
          {label}
        </label>
      ) : (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-white text-left"
        />
      )}
      <div className={className}>
        <select
          id={id}
          className={`block w-full appearance-none rounded-md border px-3 py-2 bg-${bgColour} border-${borderColour} placeholder-${placeholderColour} text-${textColour} focus:ring-none focus:ring-transparent focus:border-2 focus:border-warning sm:text-sm pr-8`}
          required={required}
          onChange={onChange}
          value={value}
        >
          {children}
        </select>
      </div>
    </div>
  );
}