import { useEffect } from "react";
import Template from "../app/Template";

export default function TermsAndConditions() {

  useEffect(() => {
    // Script for embedding privacy policy
    const script1 = document.createElement("script");
    script1.src = "https://app.termly.io/embed-policy.min.js";
    script1.async = true;
    document.head.appendChild(script1);

    return () => {
      // Clean up when component unmounts
      document.head.removeChild(script1);
    };
  }, []);

  return (
    <Template>
      <div
        data-name="termly-embed"
        data-id="24778d60-6645-4b3a-b2cd-0cacbae276b3"
        data-type="iframe"
      ></div>
      <iframe
        title="terms_and_conditions"
        style={{
          width: "100%",
          minHeight: "1500px",
          overflow: "hidden",
        }}
        src="https://app.termly.io/notify/24778d60-6645-4b3a-b2cd-0cacbae276b3"
      ></iframe>

    </Template>
  );
}
