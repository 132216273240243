import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { register } from "../../slices/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LockOpenIcon } from "@heroicons/react/24/solid";
import Checkboxes from "../layout/Checkboxes";
import { generateTranslatedText } from "../../utils/boilerplate";
import Card from "../layout/Card";
import TextInput from "../forms/TextInput";
import GoogleLoginButton from "./GoogleLoginButton";
import Button from "../layout/Button";
import Spinner from "../layout/Spinner";

export default function RegisterForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const language = "EN";

  // App level state from redux store
  const { loading } = useAppSelector((state) => state.auth);

  // Get query parameters
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get("referral_id");

  const [registerState, setRegisterState] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    over_18: false,
    agree_terms: false,
  });

  async function completeRegistration(e: any) {
    e.preventDefault();
    const {
      password,
      confirm_password: confirmPassword,
      username,
      email,
      over_18: over18,
      agree_terms: agreeTerms,
    } = registerState;

    // Validate booleans
    if (!over18) {
      dispatch(
        addAlert(generateTranslatedText("register_over_18", language), "danger")
      );
      return;
    }
    if (!agreeTerms) {
      dispatch(
        addAlert(
          generateTranslatedText("register_agree_terms", language),
          "danger"
        )
      );
      return;
    }

    const res: any = await dispatch(
      register({
        currentPassword: password,
        confirmPassword,
        email,
        username,
        referralId,
        over18,
        agreeTerms,
      })
    );
    if (res.payload.status === 200) {
      dispatch(
        addAlert(generateTranslatedText("register_success", language), "success")
      );
      navigate("/verification");
    } else {
      const usernameError = res.payload.response.data.username;
      if (usernameError === undefined) {
        dispatch(
          addAlert(generateTranslatedText("register_error", language), "danger")
        );
      } else {
        dispatch(
          addAlert(generateTranslatedText("register_username_taken_error", language), "danger")
        );
      }
    }
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Card
        colour="black-light"
        paddingX={8}
        paddingY={8}
        borderColour="warning"
        className=" w-full max-w-md space-y-8"
      >
        <div>
          <a href="/">
            <img
              className="mx-auto h-12 w-auto rounded-lg"
              src="/img/logo/logo_transparent.svg"
              alt="FilmWaffle logo"
            />
          </a>
          {/* Google OAuth */}
          <div className="my-4 flex items-center justify-center">
            <GoogleLoginButton type="sign_up" />
          </div>

          <h2 className="text-center text-lg font-bold tracking-tight text-white">
            {`${generateTranslatedText("or", language)}...`}
          </h2>
          <h2 className="mt-2 text-center text-xl font-bold tracking-tight text-white">
            {generateTranslatedText("sign_up_title", language)}
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => completeRegistration(e)}
        >
          <div className="grid grid-cols-1 gap-y-4 -space-y-px rounded-md">

            {/* Email */}
            <TextInput
              id="email"
              type="email"
              label={generateTranslatedText("email", "EN")}
              labelColour="text-white"
              placeholder={generateTranslatedText("email_placeholder", "EN")}
              autoComplete="email"
              required={true}
              onChange={(e) => setRegisterState({ ...registerState, email: e.target.value })}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              value={registerState.email}
            />

            {/* Username */}
            <TextInput
              id="username"
              type="text"
              label={generateTranslatedText("username", "EN")}
              labelColour="text-white"
              placeholder={generateTranslatedText("username_placeholder", "EN")}
              autoComplete="username"
              required={true}
              onChange={(e) => setRegisterState({ ...registerState, username: e.target.value })}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              value={registerState.username}
            />

            {/* Password */}
            <TextInput
              id="password"
              type="password"
              label={generateTranslatedText("password", "EN")}
              labelColour="text-white"
              placeholder={generateTranslatedText("choose_password_placeholder", "EN")}
              autoComplete="current-password"
              required={true}
              onChange={(e) => setRegisterState({ ...registerState, password: e.target.value })}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              value={registerState.password}
            />

            {/* Confirm password */}
            <TextInput
              id="confirm_password"
              type="password"
              label={generateTranslatedText("confirm_password", "EN")}
              labelColour="text-white"
              placeholder={generateTranslatedText("confirm_password_placeholder", "EN")}
              autoComplete="current-password"
              required={true}
              onChange={(e) => setRegisterState({ ...registerState, confirm_password: e.target.value })}
              showLabel={true}
              bgColour="black-light"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              value={registerState.confirm_password}
            />

            {/* Confirm over 18 years of age */}
            <Checkboxes
              id="over_18"
              label={generateTranslatedText("age_confirmation", language)}
              showLabel={false}
              options={[
                [
                  true,
                  generateTranslatedText("age_confirmation_detail", language),
                  generateTranslatedText(
                    "age_confirmation_final_detail",
                    language
                  ),
                  []
                ],
              ]}
              textColour="pink"
              state={registerState}
              stateName="over_18"
              onChange={setRegisterState}
              baseValue={registerState.over_18}
            />

            {/* Agreed to ToS */}
            <Checkboxes
              id="agree_terms"
              label={generateTranslatedText("agree_tos", language)}
              showLabel={false}
              options={[
                [
                  true,
                  generateTranslatedText("agree_tos_detail", language),
                  generateTranslatedText("agree_tos_final_detail", language),
                  [
                    [generateTranslatedText("user_agreement", language), "/terms-and-conditions"],
                    [generateTranslatedText("privacy_policy", language), "/privacy-policy"],
                    ["& " + generateTranslatedText("cookie_policy", language), "/cookies"],
                  ]
                ],
              ]}
              textColour="pink"
              state={registerState}
              stateName="agree_terms"
              onChange={setRegisterState}
              baseValue={registerState.agree_terms}
            />
          </div>

          {/* Submit */}
          {registerState.over_18 && registerState.agree_terms && (
            <div>
              <Button
                bgColour="warning hover:bg-warning-dark"
                textColour="text-black"
                borderColour="black"
                disabled={loading}
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black">
                  {loading ? <Spinner colour="black" size={4} /> : (
                    <LockOpenIcon className="h-6 w-6 mr-2 text-black" aria-hidden="true" />
                  )}
                </span>
                {generateTranslatedText("sign_up", language)}
              </Button>
            </div>
          )}
        </form>
      </Card>
    </div>
  );
}
