import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";


export interface OtherProfileState {
  id: string;
  username: string;
  profile: {
    id: string;
    created_date: string;
    updated_date: string | null;
    profile_picture: string | null;
    cover_picture: string | null;
    bio: string | null;
    groups: Array<[string, string]>;
    num_posts: number;
    num_comments: number;
    num_upvotes: number;
  };
};

// Define a type for the slice state
export interface ProfilesState {
  profiles: {
    data: OtherProfileState[];
    waiting: boolean;
    loading: boolean;
    errors: any[];
    options: { [key: string]: Array<{ id: string, name: string }> };
    pagination: {
      has_previous: boolean;
      has_next: boolean;
      current_page: number;
      total_pages: number;
      total_objects: number;
    };
  },
  profile: {
    data: OtherProfileState | null;
    waiting: boolean;
    loading: boolean;
    errors: any[];
  };
}

// Define the initial state using that type
const initialState: ProfilesState = {
  profiles: {
    data: [],
    waiting: false,
    loading: false,
    errors: [],
    options: {},
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0,
    },
  },
  profile: {
    data: null,
    waiting: false,
    loading: false,
    errors: [],
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get Profiles by Query
export const getProfilesByQuery = createAsyncThunk(
  "auth/profiles/query/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
      profileId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      if (obj.profileId !== null) params.profile = obj.profileId;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/auth/profile/search${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get View Only Profile by ID
export const getViewOnlyProfileById = createAsyncThunk(
  "auth/profile/id/get",
  async (
    obj: {
      profileId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      if (obj.profileId !== null) params.profile = obj.profileId;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/auth/profile/search${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Profiles by Query
      .addCase(getProfilesByQuery.pending, (state) => {
        state.profiles.loading = true;
      })
      .addCase(getProfilesByQuery.fulfilled, (state, action) => {
        state.profiles.loading = false;
        state.profiles.data = action.payload.data.data;
        state.profiles.pagination = action.payload.data.pagination;
      })
      .addCase(getProfilesByQuery.rejected, (state, action) => {
        state.profiles.loading = false;
        state.profiles.errors.push(action.payload as string);
      })

      // Get View Only Profile by ID
      .addCase(getViewOnlyProfileById.pending, (state) => {
        state.profile.loading = true;
      })
      .addCase(getViewOnlyProfileById.fulfilled, (state, action) => {
        state.profile.loading = false;
        state.profile.data = action.payload.data.data[0];
      })
      .addCase(getViewOnlyProfileById.rejected, (state, action) => {
        state.profile.loading = false;
        state.profile.errors.push(action.payload as string);
      });

  },
});

export default profilesSlice.reducer;
