import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Banner from "../layout/Banner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { confirmAccountVerification } from "../../slices/auth";
import {
  ArrowUturnLeftIcon,
  CheckBadgeIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import SpaceFiller from "../layout/SpaceFiller";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import Card from "../layout/Card";
import { generateTranslatedText } from "../../utils/boilerplate";
import ClickableLink from "../layout/ClickableLink";
import LoadingBubbles from "../layout/LoadingBubbles";

export default function VerificationConfirm() {
  const { user, loading } = useAppSelector((state) => state.auth);
  const language = "EN";
  const { profileId } = useParams();

  // Get query parameters
  const [searchParams] = useSearchParams();
  const referralId = searchParams.get("referral_id");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(confirmAccountVerification({ profileId, referralId }));
  }, [dispatch, navigate, profileId, referralId, user?.profile.is_verified]);

  return (
    <div className="w-full h-screen overflow-scroll bg-black">
      <SpaceFiller>
        {loading ? (
          <div className="flex justify-center items-center">
            <LoadingBubbles />
          </div>
        ) : user === null ? (
          <>
            {/* Card */}
            <Card
              colour="black-light"
              paddingX={8}
              paddingY={8}
              borderColour="warning"
              className=" w-full max-w-md space-y-8"
            >
              {/* Header */}
              <div>
                <a href="/">
                  <img
                    className="mx-auto h-12 w-auto rounded-lg"
                    src="/img/logo/logo_transparent.svg"
                    alt="FilmWaffle"
                  />
                </a>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                  {generateTranslatedText(
                    "verification_confirm_title_fail",
                    language
                  )}
                </h2>
                <p className="mt-5 text-secondary text-center text-sm">
                  {generateTranslatedText(
                    "verification_confirm_subtext_fail",
                    language
                  )}
                </p>
              </div>

              <div className="text-center my-4">
                <ClickableLink
                  href="/verification"
                  bgColour="warning hover:bg-warning-dark"
                  textColour="text-black"
                  className="group relative flex w-full justify-center"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <ArrowUturnLeftIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </span>
                  {generateTranslatedText("return_to_verification", language)}
                </ClickableLink>
              </div>
              <ExclamationCircleIcon
                className="mx-auto mt-10 h-20 w-20 animate-bounce text-warning"
                aria-hidden="true"
              />
            </Card>
          </>
        ) : (
          <>
            {/* Card */}
            <Card
              colour="black-light"
              paddingX={8}
              paddingY={8}
              borderColour="warning"
              className=" w-full max-w-md space-y-8"
            >
              {/* Header */}
              <div>
                <a href="/">
                  <img
                    className="mx-auto h-12 w-auto rounded-lg"
                    src="/img/logo/logo_transparent.svg"
                    alt="FilmWaffle"
                  />
                </a>
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-white">
                  {generateTranslatedText(
                    "verification_confirm_title_success",
                    language
                  )}
                </h2>
                <p className="mt-5 text-secondary text-center text-sm">
                  {generateTranslatedText(
                    "verification_confirm_subtext_success",
                    language
                  )}
                </p>
              </div>

              <div className="text-center my-4">
                <ClickableLink
                  href="/login"
                  bgColour="warning hover:bg-warning-dark"
                  textColour="text-black"
                  className="group relative flex w-full justify-center"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <ArrowRightCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </span>
                  {generateTranslatedText("login", language)}
                </ClickableLink>
              </div>
              <CheckBadgeIcon
                className="mx-auto mt-10 h-20 w-20 animate-bounce text-success"
                aria-hidden="true"
              />
            </Card>
          </>
        )}
      </SpaceFiller>

      {/* Alert banner */}
      <Banner />
    </div>
  );
}
