import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";


export interface GroupState {
  id: string;
  created_date: string;
  updated_date: string | null;
  administrators: Array<[string, string]>;
  is_administrator: boolean;
  is_member: boolean;
  num_members: number;
  name: string;
  description: string;
  category: [string, string];
  subcategory: Array<[string, string]>;
  profile_picture: string | null;
  cover_picture: string | null;
  slug: string;
  status: [string, string];
};

// Define a type for the slice state
export interface InterestState {
  groups: {
    data: GroupState[];
    waiting: boolean;
    loading: boolean;
    errors: any[];
    options: { [key: string]: Array<{ id: string, name: string }> };
    pagination: {
      has_previous: boolean;
      has_next: boolean;
      current_page: number;
      total_pages: number;
      total_objects: number;
    };
  },
  group: {
    data: GroupState | null;
    waiting: boolean;
    loading: boolean;
    errors: any[];
  };
}

// Define the initial state using that type
const initialState: InterestState = {
  groups: {
    data: [],
    waiting: false,
    loading: false,
    errors: [],
    options: {},
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0,
    },
  },
  group: {
    data: null,
    waiting: false,
    loading: false,
    errors: [],
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get Groups by Query
export const getGroupsByQuery = createAsyncThunk(
  "interest/group/query/get",
  async (
    obj: {
      pageNumber: number;
      query: string | null;
      groupId: string | null;
      profileId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      if (obj.query !== null && obj.query !== "") params.query = obj.query;
      if (obj.groupId !== null) params.id = obj.groupId;
      if (obj.profileId !== null) params.profile = obj.profileId;
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/interest/group/search${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get interest Group categories
export const getInterestGroupCategories = createAsyncThunk(
  "interest/group/categories/get",
  async (
    args,
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const url = `/api/interest/group/category`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Create interest Group
export const createGroup = createAsyncThunk(
  "interest/group/create/post",
  async (
    obj: {
      administrators: string[];
      name: string;
      description: string;
      category: string | null;
      subcategory: string[];
      profile_picture: string | null;
      cover_picture: string | null;
      status: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        administrators: obj.administrators,
        name: obj.name,
        description: obj.description,
        category: obj.category,
        subcategory: obj.subcategory,
        profile_picture: obj.profile_picture,
        cover_picture: obj.cover_picture,
        status: "A"
      });

      const res = await axios.post(`/api/interest/group`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update interest Group
export const updateGroup = createAsyncThunk(
  "interest/group/update/put",
  async (
    obj: {
      groupId: string;
      administrators: string[];
      name: string;
      description: string;
      category: string | null;
      subcategory: string[];
      profile_picture: string | null;
      cover_picture: string | null;
      status: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.groupId,
        administrators: obj.administrators,
        name: obj.name,
        description: obj.description,
        category: obj.category,
        subcategory: obj.subcategory,
        profile_picture: obj.profile_picture,
        cover_picture: obj.cover_picture,
        status: "A"
      });

      const res = await axios.put(`/api/interest/group`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete Group
export const deleteGroup = createAsyncThunk(
  "interest/group/delete/post",
  async (
    obj: {
      groupId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const headers = {
        Authorization: `Bearer ${auth.access}`,
        "Content-Type": "application/json",
      };

      const body = JSON.stringify({
        id: obj.groupId,
      });

      const res = await axios.delete(`/api/interest/group`, { data: body, headers });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Top Groups
export const getTopGroups = createAsyncThunk(
  "interest/group/top/get",
  async (
    obj: {
      pageNumber: number;
    },
    { getState, rejectWithValue }
  ) => {
    try {

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      params.page = obj.pageNumber.toString();
      const searchParams = new URLSearchParams(params).toString();
      const url = `/api/interest/group/top${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Group by Slug
export const getGroupBySlug = createAsyncThunk(
  "interest/group/slug/get",
  async (
    obj: {
      groupSlug: string | undefined;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const authCheck = auth.access === null || auth.refresh === null;
      const config = authCheck ? {} : {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      // Build endpoint URL
      const url = authCheck ? `/api/interest/group/public/slug/${obj.groupSlug}>` : `/api/interest/group/auth/slug/${obj.groupSlug}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const interestSlice = createSlice({
  name: "interest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Groups by Query
      .addCase(getGroupsByQuery.pending, (state) => {
        state.groups.loading = true;
      })
      .addCase(getGroupsByQuery.fulfilled, (state, action) => {
        state.groups.data = action.payload.data.data;
        state.groups.options = action.payload.data.options;
        state.groups.pagination = action.payload.data.pagination;
        state.groups.loading = false;
      })
      .addCase(getGroupsByQuery.rejected, (state, action) => {
        state.groups.errors = [action.payload as string];
        state.groups.loading = false;
      })

      // Get interest Group categories
      .addCase(getInterestGroupCategories.pending, (state) => {
        state.groups.loading = true;
      })
      .addCase(getInterestGroupCategories.fulfilled, (state, action) => {
        state.groups.options.category = action.payload.data.data;
        state.groups.loading = false;
      })
      .addCase(getInterestGroupCategories.rejected, (state, action) => {
        state.groups.errors = [action.payload as string];
        state.groups.loading = false;
      })

      // Create interest Group
      .addCase(createGroup.pending, (state) => {
        state.groups.loading = true;
      })
      .addCase(createGroup.fulfilled, (state, action) => {
        state.groups.data.unshift(action.payload.data);
        state.groups.loading = false;
      })
      .addCase(createGroup.rejected, (state, action) => {
        state.groups.errors = [action.payload as string];
        state.groups.loading = false;
      })

      // Update interest Group
      .addCase(updateGroup.pending, (state) => {
        state.group.loading = true;
      })
      .addCase(updateGroup.fulfilled, (state, action) => {
        state.group.data = action.payload.data
        state.group.loading = false;
      })
      .addCase(updateGroup.rejected, (state, action) => {
        state.group.errors = [action.payload as string];
        state.group.loading = false;
      })

      // Delete Group
      .addCase(deleteGroup.pending, (state) => {
        state.groups.loading = true;
      })
      .addCase(deleteGroup.fulfilled, (state, action) => {
        state.groups.data = state.groups.data.filter((group) => group.id !== action.payload.data.id);
      })
      .addCase(deleteGroup.rejected, (state, action) => {
        state.groups.errors = [action.payload as string];
        state.groups.loading = false;
      })

      // Get Top Groups
      .addCase(getTopGroups.pending, (state) => {
        state.groups.loading = true;
      })
      .addCase(getTopGroups.fulfilled, (state, action) => {
        state.groups.data = action.payload.data.data;
        state.groups.pagination = action.payload.data.pagination;
        state.groups.loading = false;
      })
      .addCase(getTopGroups.rejected, (state, action) => {
        state.groups.errors = [action.payload as string];
        state.groups.loading = false;
      })

      // Get Group by Slug
      .addCase(getGroupBySlug.pending, (state) => {
        state.group.loading = true;
      })
      .addCase(getGroupBySlug.fulfilled, (state, action) => {
        state.group.data = action.payload.data;
        state.group.loading = false;
      })
      .addCase(getGroupBySlug.rejected, (state, action) => {
        state.group.errors = [action.payload as string];
        state.group.loading = false;
      })
  },
});

export default interestSlice.reducer;
