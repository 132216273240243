import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { BookmarkIcon, ChatBubbleOvalLeftIcon, PencilSquareIcon, StarIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Tabs from "../layout/Tabs";
import { getPostsByProfile, getPostsByQueryAuthenticated, PostState } from "../../slices/content";
import FeedFilmItem from "../layout/FeedFilmItem";
import Button from "../layout/Button";
import { getGroupsByQuery, GroupState } from "../../slices/interest";
import Card from "../layout/Card";
import { getViewOnlyProfileById } from "../../slices/profiles";
import { useParams } from "react-router-dom";
import Header from "../layout/Header";
import Breadcrumbs from "../layout/Breadcrumbs";

export default function ViewOnlyProfile() {
  const dispatch = useAppDispatch();

  // Get URL params
  const { profile_id: profileId } = useParams()

  useEffect(() => {
    if (profileId === undefined) {
      // Redirect to 404 page
      window.location.href = "/404";
      return;
    }
    dispatch(getViewOnlyProfileById({ profileId }));
    dispatch(getPostsByQueryAuthenticated({ pageNumber: 1, query: null, profileId }));
  }, [dispatch, profileId]);

  // App level state from redux store
  const {
    user,
    loading,
  } = useAppSelector((state) => state.auth);
  const {
    posts: {
      data: postsData,
      loading: postsLoading,
      waiting: postsWaiting,
      pagination: postsPagination
    }
  } = useAppSelector((state) => state.content);
  const {
    groups: {
      data: groupsData,
      loading: groupsLoading,
      pagination: groupsPagination
    }
  } = useAppSelector((state) => state.interest);
  const {
    profile: {
      data: profileData,
      loading: profileLoading,
    }
  } = useAppSelector((state) => state.profiles);


  // Component level state
  const [page, setPage] = useState(1);

  // Functions
  async function clickOnTab(e: any, args: any[]) {
    setPage(1);
    e.preventDefault();
    if (user === null || profileId === undefined) {
      return;
    }
    const tabId = args[0];
    if (tabId === "posts") {
      dispatch(getPostsByQueryAuthenticated({ pageNumber: page, query: null, profileId }));
    } else if (tabId === "saved") {
      console.log("Saved");
    } else if (tabId === "groups") {
      dispatch(getGroupsByQuery({ pageNumber: page, query: null, groupId: null, profileId }));
    }
  }

  // Constant variables
  const language = "EN";
  return (
    <Template>

      {/* Header */}
      <Header
        text=""
        breadcrumbs={<Breadcrumbs breadcrumbs={[{ href: `/profiles`, text: "← Back to Find People" }]} />}
      />

      <div className="grid grid-cols-1 gap-4 mt-4">

        {/* Loading spinner */}
        {profileLoading || loading || user === null || profileData === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <>

            {/* Photos */}
            <div className="w-full">

              {/* Cover photo */}
              <img
                src={profileData.profile.cover_picture === null ? "/img/graphics/placeholder_cover_picture.svg" : profileData.profile.cover_picture}
                alt={profileData.username}
                className="w-full h-64 object-cover rounded-lg z-10"
              />

              {/* Profile photo */}
              <div className="p-1 rounded-full w-36 h-36 absolute left-1/2 transform -translate-x-1/2 z-20">
                <img
                  src={profileData.profile.profile_picture === null ? "/img/graphics/placeholder_profile_picture.svg" : profileData.profile.profile_picture}
                  alt="Profile"
                  className="w-32 h-32 object-cover rounded-full -mt-16 border-4 border-light/50 z-20"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-y-12 mt-8">
              {/* Metadata */}
              <div className="flex flex-col items-center gap-y-2 mt-16">

                {/* Username */}
                <span className="flex gap-4 flex-row items-center">
                  <h1 className="text-xl font-bold text-white">
                    {profileData.username}
                  </h1>
                </span>

                {/* Joined on */}
                <p className="text-sm text-secondary">
                  {`${generateTranslatedText("joined_on", language)} ${profileData.profile.created_date}`}
                </p>

                <div className="flex flex-row gap-4">

                  {/* Number of posts */}
                  <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                    <PencilSquareIcon className="w-5 h-5 text-warning" />
                    {`${profileData.profile.num_posts} Posts`}
                  </span>

                  {/* Number of comments */}
                  <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                    <ChatBubbleOvalLeftIcon className="w-5 h-5 text-warning" />
                    {`${profileData.profile.num_comments} Comments`}
                  </span>

                  {/* Number of upvotes */}
                  <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                    <StarIcon className="w-5 h-5 text-warning" />
                    {`${profileData.profile.num_upvotes}`}
                  </span>
                </div>

              </div>

              {/* Content */}
              <Tabs
                tabHeaders={
                  [
                    { id: "posts", name: generateTranslatedText("posts", language), icon: PencilSquareIcon, count: profileData.profile.num_posts, showCount: true },
                    { id: "saved", name: generateTranslatedText("saved", language), icon: BookmarkIcon, count: 0, showCount: false },
                    { id: "groups", name: generateTranslatedText("groups", language), icon: UserGroupIcon, count: profileData.profile.groups.length, showCount: true },
                  ]
                }
                tabContents={[
                  {
                    id: "posts",
                    content: (
                      <>
                        {postsLoading ? (
                          <div className="w-full flex justify-center my-16">
                            <LoadingBubbles />
                          </div>
                        ) : (
                          <>
                            {postsData.map((post: PostState) => (
                              <FeedFilmItem post={post} key={post.id} />
                            ))}

                            {/* Load more content */}
                            {postsPagination.has_next && (
                              <div className="flex justify-center items-center my-10">
                                {postsWaiting ? (
                                  <div className="flex justify-center items-center w-full my-20">
                                    <LoadingBubbles />
                                  </div>
                                ) : (
                                  <Button
                                    bgColour="black hover:bg-white/10"
                                    textColour="warning"
                                    borderColour="warning"
                                    className="items-center w-fit"
                                    onClick={() => {
                                      dispatch(getPostsByProfile({ pageNumber: page + 1 }))
                                      setPage(page + 1);
                                    }}
                                  >
                                    <span className="flex items-center gap-x-1 text-warning">
                                      {generateTranslatedText("load_more_content", language)}
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </>
                        )
                        }
                      </>
                    )
                  },
                  {
                    id: "groups",
                    content: (
                      <>
                        {groupsLoading ? (
                          <div className="w-full flex justify-center my-16">
                            <LoadingBubbles />
                          </div>
                        ) : (
                          <>
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                              {groupsData.map((group: GroupState) => (
                                <a href={`/group/${group.slug}`} key={group.slug} className="group">
                                  <Card className="group-hover:border-light/20">
                                    <div className="grid grid-cols-1 justify-center text-center gap-y-4 py-4">

                                      {/* Profile picture */}
                                      <div className="w-full flex justify-center">
                                        <img
                                          src={group.profile_picture === null ? "/img/graphics/placeholder_group_profile_picture.svg" : group.profile_picture}
                                          alt={group.name}
                                          className="w-32 h-32 object-cover rounded-full group-hover:ring-4 group-hover:ring-light/50"
                                        />
                                      </div>

                                      {/* Name */}
                                      <h1 className="text-xl font-bold text-white">
                                        {group.name}
                                      </h1>

                                      {/* Created at */}
                                      <p className="text-sm text-secondary">
                                        {`${generateTranslatedText("created_on", language)} ${group.created_date}`}
                                      </p>

                                      <div className="flex justify-center gap-4">

                                        {/* Number of members */}
                                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                                          <UserGroupIcon className="w-5 h-5 text-warning" />
                                          {`${group.num_members} ${group.num_members === 1 ? "Member" : "Members"}`}
                                        </span>
                                      </div>

                                    </div>
                                  </Card>
                                </a>
                              ))}
                            </div>

                            {/* Load more content */}
                            {groupsPagination.has_next && (
                              <div className="flex justify-center items-center my-10">
                                {postsWaiting ? (
                                  <div className="flex justify-center items-center w-full my-20">
                                    <LoadingBubbles />
                                  </div>
                                ) : (
                                  <Button
                                    bgColour="black hover:bg-white/10"
                                    textColour="warning"
                                    borderColour="warning"
                                    className="items-center w-fit"
                                    onClick={() => {
                                      dispatch(getGroupsByQuery({ pageNumber: page + 1, query: null, groupId: null, profileId: user?.profile.id }))
                                      setPage(page + 1);
                                    }}
                                  >
                                    <span className="flex items-center gap-x-1 text-warning">
                                      {generateTranslatedText("load_more_content", language)}
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </>
                        )
                        }
                      </>
                    )
                  },
                ]}
                onClickTab={clickOnTab}
              />
            </div>

          </>
        )}

      </div>
    </Template>
  );
}
