import { useEffect } from "react";
import Template from "../app/Template";

export default function PrivacyPolicy() {

  useEffect(() => {
    // Script for embedding privacy policy
    const script1 = document.createElement("script");
    script1.src = "https://app.termly.io/embed-policy.min.js";
    script1.async = true;
    document.head.appendChild(script1);

    return () => {
      // Clean up when component unmounts
      document.head.removeChild(script1);
    };
  }, []);

  return (
    <Template>
      <div
        data-name="termly-embed"
        data-id="4e4a74b4-47c4-40c2-a099-a337d5f33f4c"
        data-type="iframe"
      ></div>
      <iframe
        title="privacy_policy"
        style={{
          width: "100%",
          minHeight: "1500px",
          overflow: "hidden",
        }}
        src="https://app.termly.io/notify/4e4a74b4-47c4-40c2-a099-a337d5f33f4c"
      ></iframe>

    </Template>
  );
}
