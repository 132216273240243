import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";

export interface EntryState {
  id: string;
  action_time: string;
  user: string;
  content_type: {
    id: string;
    app_label: string;
    model: string;
  };
  object_id: string;
  object_repr: string;
  action_flag: number;
  change_message: null | { [key: string]: any };
}

// Define a type for the slice state
export interface LogState {
  log: {
    data: EntryState[];
    waiting: boolean;
    loading: boolean;
    errors: any[];
    pagination: {
      has_previous: boolean;
      has_next: boolean;
      current_page: number;
      total_pages: number;
      total_objects: number;
    };
  },
  insights: {
    data: { [key: string]: any };
    waiting: boolean;
    loading: boolean;
    errors: any[];
  }
}

// Define the initial state using that type
const initialState: LogState = {
  log: {
    data: [],
    waiting: false,
    loading: false,
    errors: [],
    pagination: {
      has_previous: false,
      has_next: false,
      current_page: 1,
      total_pages: 1,
      total_objects: 0,
    },
  },
  insights: {
    data: {},
    waiting: false,
    loading: false,
    errors: [],
  }
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get log
export const getLog = createAsyncThunk(
  "log/get",
  async (
    obj: {
      page: number | null;
      dateRange: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      if (obj.page !== null) params.page = obj.page.toString();
      if (obj.dateRange !== null) params.dateRange = obj.dateRange;
      const searchParams = new URLSearchParams(params);
      const url = `api/log?${searchParams.toString()}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get reviewer overview insights
export const getReviewerInsightsOverview = createAsyncThunk(
  "log/reviewer/insights/overview",
  async (
    args,
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const url = `api/log/insights/reviewer/overview`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const logSlice = createSlice({
  name: "log",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get log
      .addCase(getLog.pending, (state) => {
        state.log.loading = true;
      })
      .addCase(getLog.fulfilled, (state, action) => {
        state.log.loading = false;
        state.log.data = action.payload.data.data;
        state.log.pagination = action.payload.data.pagination;
      })
      .addCase(getLog.rejected, (state) => {
        state.log.loading = false;
      })

      // Get reviewer overview insights
      .addCase(getReviewerInsightsOverview.pending, (state) => {
        state.insights.loading = true;
      })
      .addCase(getReviewerInsightsOverview.fulfilled, (state, action) => {
        state.insights.loading = false;
        state.insights.data = action.payload.data;
      })
      .addCase(getReviewerInsightsOverview.rejected, (state) => {
        state.insights.loading = false;
      });
  },
});

export default logSlice.reducer;
