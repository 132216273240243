import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { ChatBubbleOvalLeftIcon, PencilSquareIcon, StarIcon } from "@heroicons/react/24/outline";
import Button from "../layout/Button";
import Card from "../layout/Card";
import Header from "../layout/Header";
import SearchInputWithIcon from "../forms/SearchInputWithIcon";
import { getProfilesByQuery, OtherProfileState } from "../../slices/profiles";

export default function Profiles() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getProfilesByQuery({ pageNumber: 1, query: null, profileId: null }));
  }, [dispatch]);

  // App level state from redux store
  const {
    user,
    loading,
  } = useAppSelector((state) => state.auth);
  const {
    profiles: {
      data: profilesData,
      loading: profilesLoading,
      waiting: profilesWaiting,
      pagination: profilesPagination
    }
  } = useAppSelector((state) => state.profiles);


  // Component level state
  const [page, setPage] = useState(1);
  const [textQuery, setTextQuery] = useState("");

  // Functions
  async function fetchProfilesByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    setTextQuery(query);
    dispatch(getProfilesByQuery({ pageNumber, query, profileId: null }));
  }

  // Constant variables
  const language = "EN";
  return (
    <Template>

      {/* Header */}
      <Header
        text={generateTranslatedText("profiles", language)}
      />

      <div className="grid grid-cols-1 gap-4 mt-6 px-4 sm:px-8 md:px-12 lg:px-20">

        {/* Loading spinner */}
        {profilesLoading || loading || user === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <div className="grid grid-cols-1 gap-10 pb-12">

            {/* Search bar */}
            <SearchInputWithIcon
              id="search-profiles"
              name="search-profiles"
              placeholder={generateTranslatedText("search_profiles", language)}
              bgColour="black"
              textColour="white"
              borderColour="light/50"
              placeholderColour="light/50"
              widthClass="w-full"
              onSubmit={fetchProfilesByPageAndQuery}
              onSubmitArgs={[page]}
              loading={loading}
            />

            {/* Content */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {profilesData.map((profile: OtherProfileState) => (
                <a href={`/profile/${profile.profile.id}`} key={profile.id} className="group">
                  <Card className="group-hover:border-light/20">
                    <div className="grid grid-cols-1 justify-center text-center gap-y-4 py-4">

                      {/* Profile picture */}
                      <div className="w-full flex justify-center">
                        <img
                          src={profile.profile.profile_picture === null ? "/img/graphics/placeholder_profile_picture.svg" : profile.profile.profile_picture}
                          alt={profile.username}
                          className="w-32 h-32 object-cover rounded-full group-hover:ring-4 group-hover:ring-light/50"
                        />
                      </div>

                      {/* Name */}
                      <h1 className="text-xl font-bold text-white truncate">
                        {profile.username}
                      </h1>

                      {/* Created at */}
                      <p className="text-sm text-secondary">
                        {`${generateTranslatedText("joined_on", language)} ${profile.profile.created_date}`}
                      </p>

                      <div className="flex justify-center gap-4">

                        {/* Number of posts */}
                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                          <PencilSquareIcon className="w-5 h-5 text-warning" />
                          {profile.profile.num_posts}
                        </span>

                        {/* Number of comments */}
                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                          <ChatBubbleOvalLeftIcon className="w-5 h-5 text-warning" />
                          {profile.profile.num_comments}
                        </span>

                        {/* Number of upvotes */}
                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                          <StarIcon className="w-5 h-5 text-warning" />
                          {profile.profile.num_upvotes}
                        </span>

                      </div>

                    </div>
                  </Card>
                </a>
              ))}
            </div>

            {/* Load more content */}
            {profilesPagination.has_next && (
              <div className="flex justify-center items-center my-10">
                {profilesWaiting ? (
                  <div className="flex justify-center items-center w-full my-20">
                    <LoadingBubbles />
                  </div>
                ) : (
                  <Button
                    bgColour="black hover:bg-white/10"
                    textColour="warning"
                    borderColour="warning"
                    className="items-center w-fit"
                    onClick={(e) => {
                      dispatch(getProfilesByQuery({ pageNumber: page + 1, query: textQuery, profileId: null }));
                      setPage(page + 1);
                    }}
                  >
                    <span className="flex items-center gap-x-1 text-warning">
                      {generateTranslatedText("load_more_content", language)}
                    </span>
                  </Button>
                )}
              </div>
            )}

          </div>
        )}

      </div>
    </Template>
  );
}
