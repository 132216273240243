import React, { useContext, createContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AdState, getBannerAds } from "../../slices/advertisement";
import { useAppSelector } from "../../hooks";
import { AppDispatch } from "../../store";

// Create context for provider
const AdsContext = createContext<{
  banner: {
    data: AdState[];
    loading: boolean;
  };
}>({ banner: { data: [], loading: false } });
export function useAds() {
  return useContext(AdsContext);
}

interface AdsProviderProps {
  children: React.ReactNode;
}

// Create provider
export default function AdsProvider({
  children,
}: AdsProviderProps) {
  const dispatch = useDispatch<AppDispatch>();

  const { banner: { data, loading } } = useAppSelector((state) => state.advertisement);

  useEffect(() => {
    dispatch(getBannerAds());
  }, [dispatch]);

  return (
    <AdsContext.Provider value={{ banner: { data, loading } }}>
      {children}
    </AdsContext.Provider>
  );
}
