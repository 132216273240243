import { useEffect, useState } from "react";
import { AdState } from "../../slices/advertisement";

export default function BannerAds({ ads = [] }: { ads: AdState[] }) {

  // Component level state
  const [index, setIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setIndex((prev) => (prev === ads.length - 1 ? 0 : prev + 1));
        setIsFading(false); // Trigger fade-in
      }, 200); // Match the transition duration
    }, 25000);
    return () => clearInterval(interval);
  }, [ads]);

  if (ads.length === 0) return null;
  return (
    <div className="relative overflow-hidden w-full h-32 sm:h-48 rounded-md mb-4">
      {ads.map((ad, i) => (
        <img
          key={i}
          src={ad.image}
          alt={ad.title}
          className={`absolute w-full h-full object-cover rounded-md transition-opacity duration-200 ease-in-out ${i === index ? (isFading ? "opacity-0" : "opacity-100") : "opacity-0"
            }`}
        />
      ))}
    </div>
  );
}
