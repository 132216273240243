import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import {
  removePhotoFromUploads,
  validateAndCompressImage,
} from "../../slices/uploads";
import { useDropzone } from "react-dropzone";
import Spinner from "../layout/Spinner";
import { TrashIcon } from "@heroicons/react/24/outline";
import Button from "../layout/Button";
import { generateTranslatedText } from "../../utils/boilerplate";
import { PhotoIcon } from "@heroicons/react/24/outline";

interface UploadImageDropzoneProps {
  id: string;
  label?: string;
  className?: string;
  bgColour?: string;
  borderColour?: string;
  textColour?: string;
  limit: number;
  savedImages: Array<{ id: string; data: string }>;
  blockId?: string | null;
}

export default function UploadImageDropzone({
  id,
  label = "",
  className = "",
  bgColour = "black",
  borderColour = "white",
  textColour = "white",
  limit,
  savedImages,
  blockId = null,
}: UploadImageDropzoneProps) {
  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { images } = useAppSelector((state) => state.uploads);

  // Functions
  function tooManyPhotos() {
    dispatch(
      addAlert(generateTranslatedText("max_photos_reached_post", language), "warning"),
    );
  }

  const handleDrop = useCallback((acceptedFiles: any) => {
    for (let i = 0; i < acceptedFiles.length; i += 1) {
      const file = acceptedFiles[i];
      const { name } = file;
      const fileId = id;
      // const fileId = id !== "" ? id : uuidv4();
      const reader: any = new FileReader();
      const timestamp = Date.now();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const b64Img = reader.result?.split("base64,")[1];
        dispatch(
          validateAndCompressImage({
            fileId,
            name,
            timestamp,
            b64Img,
            extension: file.type.split("/")[1],
            blockId,
          }),
        );
      };
    }
  }, []);

  function dropPhoto(acceptedFiles: any[], numPhotos: number) {
    if (numPhotos + filteredSavedImages.length + acceptedFiles.length > limit) {
      tooManyPhotos();
      return;
    }
    handleDrop(acceptedFiles);
  }

  function createSource(source: string, data: any) {
    if (source === "url") return data;
    if (source === "base64") return `data:image/png;base64, ${data}`;
    return data;
  }

  function addPhotoToRemoved(e: any, args: any[]) {
    const [fileId] = args;
    dispatch(removePhotoFromUploads(fileId, blockId));
  }

  function renderProcessedImage(img: any) {
    if (!sortedImageIds.includes(img.id)) return null;
    if (img === undefined) return null;
    const { name, loading, success, data } = img;
    if (
      name === undefined ||
      loading === undefined ||
      success === undefined ||
      data === undefined
    )
      return null;
    if (loading) {
      return (
        <div className={`rounded-md card bg-${bgColour} mx-2 mb-4 p-4`} key={img.id}>
          <Spinner size={8} className="mx-auto" padding={2} colour="warning" />
          <div className="">
            <p className="text-xs text-secondary italic break-words">
              {name.length <= 25 ? name : name.slice(0, 25) + "..."}
            </p>
          </div>
        </div>
      );
    }
    if (!success) {
      dispatch(
        addAlert(generateTranslatedText("process_photo_error", language), "danger"),
      );
      return null;
    }
    if (data === null) {
      dispatch(
        addAlert(generateTranslatedText("process_photo_error", language), "danger"),
      );
      return null;
    }
    return (
      <div className="mx-2 mb-4 text-left" key={img.id}>
        <img
          className="rounded-md border-0 max-w-48 max-h-48"
          src={`data:image/png;base64, ${data}`}
          alt="Uploaded image"
          aria-hidden="true"
        />
        <Button
          type="button"
          className="mt-2 bg-transparent hover:bg-danger/10"
          onClick={addPhotoToRemoved}
          onClickArgs={[img.id]}
          border="none"
          bgColour="transparent"
        >
          <TrashIcon className={`h-5 w-5 text-${textColour} group-hover:text-danger`} aria-hidden="true" />
        </Button>
      </div>
    );
  }

  // Callbacks
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      dropPhoto(acceptedFiles, pageImages[id] === undefined ? 0 : Object.keys(pageImages[id]).length);
    },
  });

  // Constant variables
  const language = "EN";
  const pageImages = images.data;
  const sortedImages = pageImages[id] === undefined ? [] : [pageImages[id]];
  const sortedImageIds = sortedImages.map((x: any) => x.id);
  let dropzoneBorderClass = borderColour;
  if (isFocused) {
    dropzoneBorderClass = "primary";
  }
  if (isDragAccept) {
    dropzoneBorderClass = "success";
  }
  if (isDragReject) {
    dropzoneBorderClass = "danger";
  }
  const filteredSavedImages = savedImages.filter((x) => !images.removed.includes(x.id));

  return (
    <div id={id} className={`w-full ${className} cursor-pointer`}>
      {label.length > 0 && (
        <p className="mb-3 block text-sm font-medium text-white text-left">
          {label}
        </p>
      )}
      <div className="grid grid-cols-1" id="form-group-row">

        {/* Dropzone */}
        <div className="container mb-4">
          <div
            {...getRootProps({
              className: `grid grid-cols-1 justify-center text-center md:px-12 px-8 py-4 border border-dashed border-${dropzoneBorderClass} rounded-lg bg-${bgColour}`,
            })}
          >
            <input {...getInputProps()} />
            <div className="row justify-center text-center">
              <PhotoIcon className={`h-6 w-6 text-${textColour} mx-auto`} />
            </div>
            <div className="row">
              <p className={`pt-2 text-sm text-${textColour}`}>
                {generateTranslatedText("upload_image_dropzone", language)}
              </p>
            </div>
          </div>
        </div>

        {/* Uploaded images */}
        <div className="flex flex-row flex-wrap gap-2">
          {sortedImages.length > 0 &&
            sortedImages.map((x: any) => renderProcessedImage(x))}
          {filteredSavedImages.length > 0 &&
            filteredSavedImages.map((x: any) => (
              <div className="mx-2 mb-4 text-left" key={x.id}>
                <img
                  className="rounded-md border-0 max-w-48 max-h-48"
                  src={createSource("url", x.data)}
                  alt="Uploaded image"
                  aria-hidden="true"
                />
                <Button
                  type="button"
                  className="mt-2 bg-transparent hover:bg-danger/10"
                  onClick={addPhotoToRemoved}
                  onClickArgs={[x.id]}
                  bgColour="transparent"
                  border="none"
                  borderColour="transparent"
                >
                  <TrashIcon className={`h-5 w-5 text-${textColour} group-hover:text-danger`} aria-hidden="true" />
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}