import { useEffect } from "react";
import Template from "../app/Template";

export default function Cookies() {

  useEffect(() => {
    // Script for embedding privacy policy
    const script1 = document.createElement("script");
    script1.src = "https://app.termly.io/embed-policy.min.js";
    script1.async = true;
    document.head.appendChild(script1);

    return () => {
      // Clean up when component unmounts
      document.head.removeChild(script1);
    };
  }, []);

  return (
    <Template>
      <div
        data-name="termly-embed"
        data-id="7b07d18d-d007-4fe5-9d1b-5949de708b05"
        data-type="iframe"
      ></div>
    </Template>
  );
}
