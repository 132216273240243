import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import Banner from "../layout/Banner";
import LoginForm from "./LoginForm";

export default function Login() {
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user || isAuthenticated) {
      navigate("/");
    }
  }, [navigate, user, isAuthenticated]);

  return (
    <div className="w-full h-screen bg-black">
      {/* Login form */}
      <LoginForm />

      {/* Alert banner */}
      <Banner />
    </div>
  );
}
