import { useAppSelector } from "../../hooks";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function PrivateRoute({
  allowedGroups,
  title
}: {
  allowedGroups: string[];
  title: string;
}) {
  const { isAuthenticated, loading, user } = useAppSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, []);

  useEffect(() => {
    // If loading, do nothing
    if (loading) return;

    // If user is null after loading, redirect to login
    if (user === null) {
      navigate("/login");
      return;
    }

    // If not authenticated, redirect to login
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }

    // Check if user auth groups allows access to this page. If not, redirect to 404
    const groupCheck = user.auth.groups.filter((group) =>
      allowedGroups.includes(group[1])
    );
    if (groupCheck.length === 0) {
      navigate("/404");
      return;
    }
  }, [navigate, isAuthenticated, loading, user, allowedGroups]);

  return <Outlet />;
}
