import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { AuthState } from "./auth";


// Define a type for the slice state
export interface IntegrationState {
  tiktok: {
    response: any;
    success: boolean;
    loading: boolean;
    errors: string[];
  };
  instagram: {
    response: any;
    success: boolean;
    loading: boolean;
    errors: string[];
  };
  facebook: {
    response: any;
    success: boolean;
    loading: boolean;
    errors: string[];
  };
}

// Define the initial state using that type
const initialState: IntegrationState = {
  tiktok: {
    response: null,
    success: false,
    loading: false,
    errors: [],
  },
  instagram: {
    response: null,
    success: false,
    loading: false,
    errors: [],
  },
  facebook: {
    response: null,
    success: false,
    loading: false,
    errors: [],
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get TikTok access token
export const getTikTokAccessToken = createAsyncThunk(
  "integration/tiktok/access-token/get",
  async (
    obj: {
      profileId: string;
      code: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      if (obj.code) params.code = obj.code;
      const searchParams = new URLSearchParams(params).toString();
      const url = `api/integration/tiktok/callback${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Instagram access token
export const getInstagramAccessToken = createAsyncThunk(
  "integration/instagram/access-token/get",
  async (
    obj: {
      profileId: string;
      code: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      if (obj.code) params.code = obj.code;
      const searchParams = new URLSearchParams(params).toString();
      const url = `api/integration/instagram/callback${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Get Facebook access token
export const getFacebookAccessToken = createAsyncThunk(
  "integration/facebook/access-token/get",
  async (
    obj: {
      profileId: string;
      code: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Build endpoint URL
      const params: { [key: string]: string } = {};
      if (obj.code) params.code = obj.code;
      const searchParams = new URLSearchParams(params).toString();
      const url = `api/integration/facebook/callback${searchParams === "" ? "" : "?" + searchParams}`;

      // Send request
      const res = await axios.get(url, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get TikTok access token
      .addCase(getTikTokAccessToken.pending, (state) => {
        state.tiktok.loading = true;
        state.tiktok.errors = [];
      })
      .addCase(getTikTokAccessToken.fulfilled, (state, action) => {
        state.tiktok.loading = false;
        state.tiktok.success = true;
        state.tiktok.response = action.payload.data;
      })
      .addCase(getTikTokAccessToken.rejected, (state, action) => {
        state.tiktok.loading = false;
        state.tiktok.errors = [action.payload as string];
      })

      // Get Instagram access token
      .addCase(getInstagramAccessToken.pending, (state) => {
        state.instagram.loading = true;
        state.instagram.errors = [];
      })
      .addCase(getInstagramAccessToken.fulfilled, (state, action) => {
        state.instagram.loading = false;
        state.instagram.success = true;
        state.instagram.response = action.payload.data;
      })
      .addCase(getInstagramAccessToken.rejected, (state, action) => {
        state.instagram.loading = false;
        state.instagram.errors = [action.payload as string];
      })

      // Get Facebook access token
      .addCase(getFacebookAccessToken.pending, (state) => {
        state.facebook.loading = true;
        state.facebook.errors = [];
      })
      .addCase(getFacebookAccessToken.fulfilled, (state, action) => {
        state.facebook.loading = false;
        state.facebook.success = true;
        state.facebook.response = action.payload.data;
      })
      .addCase(getFacebookAccessToken.rejected, (state, action) => {
        state.facebook.loading = false;
        state.facebook.errors = [action.payload as string];
      });

  },
});

export default integrationSlice.reducer;
