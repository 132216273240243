interface CardProps {
  colour?: string;
  children: React.ReactNode;
  className?: string;
  paddingX?: number;
  paddingY?: number;
  borderColour?: string;
}

export default function Card({
  colour = "black-light",
  children,
  className = "",
  paddingX = 4,
  paddingY = 4,
  borderColour = "black",
}: CardProps) {
  return (
    <div
      className={`bg-${colour} rounded-md px-${paddingX} py-${paddingY} overflow-x-auto border border-${borderColour} scrollbar-invisible ${className}`}
    >
      {children}
    </div>
  );
}
