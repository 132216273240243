import { configureStore } from "@reduxjs/toolkit";
import alertSlice from "./slices/alert";
import authSlice from "./slices/auth";
import logSlice from "./slices/log";
import integrationSlice from "./slices/integration";
import contentSlice from "./slices/content";
import uploadsSlice from "./slices/uploads";
import notificationSlice from "./slices/notification";
import interestSlice from "./slices/interest";
import profilesSlice from "./slices/profiles";
import advertisementSlice from "./slices/advertisement";

export const store = configureStore({
  reducer: {
    alert: alertSlice,
    auth: authSlice,
    log: logSlice,
    integration: integrationSlice,
    content: contentSlice,
    uploads: uploadsSlice,
    notification: notificationSlice,
    interest: interestSlice,
    profiles: profilesSlice,
    advertisement: advertisementSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
